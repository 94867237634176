export const ACCOUNT_API_URL = {
    listCatalog:'/catalog/list',
    searchCatalog:'/catalog/search',
    payments:'/payments',
    searchCatalogById:'/catalog/search-catalog-items',
    findUnique : '/catalog/object',

    // listCatalog:'/catalog/search-catalog-items',
    payments:'/payments',

    orders:'/orders',
    orderUpdate:'/orders',
    ordersRetrieve:'/orders',
    ordersUpdate:'/orders',
}
