import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  ButtonGroup,
} from "@mui/material";
import BannerBg from "../assets/images/banner-bg.jpg";
import NewFoods from "../assets/images/new-foods.jpg";
import Slider from "react-slick";
import BannerImg  from "../assets/images/banner-img.png";
import { ReactComponent as RightArrow } from "../assets/images/right-arrow.svg";
import OurStoryImg  from "../assets/images/our-story.jpg";
import DishImg2 from "../assets/images/dish2.png";
import DishImg3 from "../assets/images/dish3.png";
import DishImg4 from "../assets/images/dish4.png";
import Food1 from "../assets/images/food1.jpg";
import Food2 from "../assets/images/food2.png";
import Food3 from "../assets/images/food3.png";
// import User1 from "../assets/images/user-1.png";
// import User2 from "../assets/images/user-2.png";
// import User3 from "../assets/images/user-3.png";
// import User4 from "../assets/images/user-4.png";
import UserBG from "../assets/images/user-bg.png";
import Skeleton from "@mui/material/Skeleton";
import "./page-styles.scss";
import { ListCatalog, searchCatalog, findUniqueData, SearchCatalogByCatagoryId } from "../sevices";
import { useNavigate } from "react-router-dom";
import Context from "../Context";
import Cards from "./Cards";
import { Category } from "@mui/icons-material";
import Loader from "../constant/Loader";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LoadImg from "../assets/images/slow-cooker-loader.gif";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
// import { ReactComponent as Minus } from "../assets/images/minus.svg";
// import { ReactComponent as Plus } from "../assets/images/plus.svg";
import { ReactComponent as Minus1 } from '../assets/images/minus1.svg'
import { ReactComponent as Plus1 } from "../assets/images/Plus1.svg";

const cardSettings = {
  dots: false,
  infinite: false,
  arrows: false,
  slidesToShow: 4.1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const NewFoodSlide = {
  dots: false,
  infinite: false,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const CustomerSlider = {
  dots: false,
  infinite: false,
  arrows: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
function LandingScreen() {
  // const [foodData, setFoodData] = useState([]);
  const [showData, setShowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [cardloading, setcardloading] = useState(false);
  const [showCategory, setShowCategory] = useState(false);

  // const [optionValue, setOptionValue] = useState({});
  const { unique, setUnique } = useContext(Context);

  const navigate = useNavigate();

  const [selectedValue, setSelectedValue] = useState("");

  const handleRadioChange = (event) => {
    console.log("asfsdfsadfafasf", event.target.value);
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    // fetchData();
    // fetchOptionValue();
    itemData();

    getDataFromLocalStorage()
    getCategoryDataFromLocalStorage()
  }, []);

  const getDataFromLocalStorage = async () => {
    const storedData = await localStorage.getItem('CardsData');
    if (storedData) {
      let filteredstoredData = JSON.parse(storedData).slice(0, 4)
      setcardloading(false)
      setShowData(filteredstoredData)
    }
  }
  const getCategoryDataFromLocalStorage = async () => {
    const storedDataCategoryData = await localStorage.getItem('CategoryData');
    const Category = await localStorage.getItem('CategoryText');
    setSelectedValue(JSON.parse(Category))
    if (storedDataCategoryData) {
      setcardloading(false)
      setCategoryData(JSON.parse(storedDataCategoryData))
    }
  }

  console.log('sellelelel',selectedValue);

  useEffect(() => {
    if (categoryData?.length === 0 ) {
   
      // let category = categoryData?.find((catgry) =>  catgry?.category_data?.name === selectedValue)
      // console.log('categoryData[0]?.category_data?.name', category);
      showCategory && hanldleButton(selectedValue)
    }

  }, [categoryData]);

  console.log('slexred vall',selectedValue);

  // const fetchOptionValue = async () => {
  //   try {
  //     let data = {
  //       object_types: ["ITEM_OPTION"],
  //     };
  //     const response = await searchCatalog(data);
  //     console.log(
  //       "CKJNKJNAC",
  //       response?.data?.objects[0]?.item_option_data?.values
  //     );
  //     setOptionValue(response?.data?.objects[0]?.item_option_data?.values);
  //   } catch (err) {
  //     console.log("ERRR", err);
  //   }
  // };

  // const fetchData = async () => {
  //   try {
  //     let data = {
  //       object_types: ["ITEM"],
  //     };
  //     setcardloading(true);
  //     const response = await searchCatalog(data);
  //     await localStorage.setItem('CardsData', JSON.stringify(response?.data?.objects));
  //     let data1 = response?.data?.objects?.slice(0, 4);
  //     setcardloading(false);
  //     console.log("data1------->", data1);
  //     setFoodData(data1);
  //     // setShowData(data1);
  //   } catch (err) {
  //     console.log("ERRR", err);
  //   }
  // };

  const itemData = async () => {
    try {
      let data = {
        object_types: ["CATEGORY"],
      };
      const response = await searchCatalog(data);
      if (response?.data?.objects) {
        await localStorage.setItem('CategoryData', JSON.stringify(response?.data?.objects));
        console.log("response?.data?.objects", response?.data?.objects);
  
        setCategoryData(response?.data?.objects);
  
        hanldleButton(selectedValue)
      }
    } catch (err) {
      console.log("ERRR", err);
    } 
  };

  const hanldleButton = async (data, i, e) => {
    setShowCategory(true)
    // setSelectedValue(data)
    await localStorage.setItem('CategoryText', JSON.stringify(data))
    console.log("dsatta->", data);
    // setShowData(null);
    // let foodCategory = await Promise.all(
    let foundCategory = await categoryData.find((dt) => dt?.category_data.name === data)
    const categoriesToMap = foundCategory ? [foundCategory] : categoryData;

    const CatagoryItems = await Promise.all(categoriesToMap.map(async (category) => {

      const itemResponse = await SearchCatalogByCatagoryId({ category_ids: [category.id] });

      if (itemResponse?.data?.items) {
        setcardloading(true)
        let filteredShowData = itemResponse?.data?.items?.slice(0, 4)
        console.log('filteredShowData', filteredShowData);
        setShowData(filteredShowData)
        console.log('itemResponse?.data?.items', itemResponse?.data?.items);
        setcardloading(false)

        await localStorage.setItem('CardsData', JSON.stringify(itemResponse?.data?.items));
      }
    }));


  };

  useEffect(() => {
    const setMobileTransform = () => {
      if (window.innerWidth <= 767) {
        const track = document.querySelector(".slick-track");
        if (track) {
          track.style.transform = "translate3d(0px, 0px, 0px)";
        }
      }
    };
    setMobileTransform();
    window.addEventListener("resize", setMobileTransform);
    return () => {
      window.removeEventListener("resize", setMobileTransform);
    };
  }, []);

  console.log('ShowData-------->', showData);

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 2,

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  console.log("jhvsdfv", showData);
  // const displayCounter = counter > 0;
  return (
    <div className="LandingPageContainer" style={{marginBottom:'40px'}}>
      <div
        className="BannerContainer"
        style={{ backgroundImage: `url(${BannerBg})` }}
      >
        <Grid container spacing={2}>
          <Grid lg={7} sm={9} xs={10}>
            <div className="BannerContent">
              <div className="BannerContentTitle">
                {/* Escape to South India From USA */}
                Escape to South India From USA
              </div>
              <div className="BannerContentText">
                <span>
                  Whether it's indulging in comfort food, exploring Indian
                  flavors, or catering to dietary preferences, Indulge in the
                  vibrant flavors of South India right here in the heart of the
                  USA.
                </span>
                {/* <Button className="payBtn" onClick={() => navigate("/our-menu")}>Order now</Button> */}
              </div>

            </div>
          </Grid>
        </Grid>
        <div className="BannerImage">
          {/* <BannerImg /> */}
          <img src={BannerImg} className="landingImg" />
        </div>
      </div>

      <div className="OurMenuContainer">
        {/* <Grid container justifyContent="center">
          <Grid lg={12}>
            <div className="MainTitle">Our Menu</div>
          </Grid>
        </Grid> */}
        {/* <div className="OurMenuContentSet">
          <Grid container justifyContent="center">
            <Grid md={6} sm={12}>
              <div className="OurMenuContent">
                The presentation and layout of a food menu are crucial for
                creating an attractive and user-friendly experience. Clear
                sections, visually appealing images, and consistent formatting
                contribute to an organized and easy-to-navigate menu. A
                well-structured menu can help customers make informed decisions,
                explore new flavors, and ultimately enjoy their dining
                experience to the fullest.
              </div>
            </Grid>
          </Grid>
          <Button
            className="ExploreAllBtn"
            onClick={() => navigate("/our-menu")}
          >
            <span>Explore all</span>
            <RightArrow />
          </Button>
        </div> */}
        <div style={{ marginLeft: '50px', marginRight: '50px' }}>
          {/* <div className="FilterBtnSet" > */}
            {/* <Slider {...settings}>
              {categoryData.map((slide, index) => (
                <div key={index}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={selectedValue}
                    onChange={handleRadioChange}
                    onClick={(e) =>
                      hanldleButton(slide?.category_data?.name, index, e)
                    }
                  >
                    <FormControlLabel
                      value={slide?.category_data?.name}
                      control={<Radio />}
                      label={slide?.category_data?.name}
                    />
                  </RadioGroup>
                </div>
              ))}
            </Slider> */}
            {/* <hr style={{position:'absolute',bottom:10}}/> */}
            {/* <div className="itemContainer">
              {showData?.map((dt, i) => (

                <Cards
                  key={dt.id}

                  i={i}
                  data={dt}
                  unique={unique}
                  setUnique={setUnique}
                  loading={loading}
                  setLoading={setLoading}
                />

              ))}



            </div> */}

            <div className="MenuItemsSlider">
              {cardloading ? ( ""
                // <div
                //   style={{
                //     display: "flex",
                //     justifyContent: "center",
                //     alignItems: "center",
                //     padding: "65px",
                //   }}
                // >
                //   <img
                //     src={LoadImg}
                //     alt="Loading..."
                //     className="loader-img"
                //     style={{ width: "80px", height: "80px" }}
                //   />
                // </div>
              ) : !showData?.length ? ( ""
                // <div
                //   className="slick-list newclass"
                //   style={{
                //     display: "flex",
                //     justifyContent: "center",
                //     alignItems: "center",
                //     padding: "65px",
                //     flexDirection: "column",
                //   }}
                // >
                //   <ContentPasteOffIcon style={{ fontSize: "40px" }} />
                //   <img src={LoadImg} alt="Loading..." className="loader-img" style={{ width: '80px', height: '80px' }} />
                //   <p>No Items</p>
                // </div>
              ) : (
                null
              )}
            </div>



          {/* </div> */}





        </div>


      </div>
      <div className="OurStoryContainer">
        <Grid container spacing={10}>
          <Grid item lg={6}>
            {/* <div className="OurStoryImgContainer"  style={{ backgroundImage: `url(${OurStoryImg})` }}>
          
            </div> */}
            <div className="imgContainer">
            <img src={OurStoryImg} className="ImgourStory" />
            </div>
            
            
          </Grid>
          <Grid item lg={6}>
            <div className="OurStoryContent" >
              <div className="MainTitle">Our Story</div>
              <p>
                At Namma Restaurant, we claim that every meal has a story.
                Traditional dishes prepared over hot stones, coal grills and
                conventional tandoori ovens. Namma’s menu exhibits Indian
                culinary evolution with its melting pot of distinctive regional
                food traditions and cultural heritage perfected in villages and
                culinary hotspots across India
                {/* In the bustling city of San Francisco, a small, family-owned South Indian restaurant named "Spice Haven" opened its doors for the first time. The aroma of freshly ground spices wafted through the air, enticing passersby to step inside and embark on a culinary journey. */}
              </p>
              <p>
                Our chefs expertly prepare Indian food, transporting you to an
                era where natural fire and smokey flavor were the basic cooking
                form. The ancient technique allows direct heat of natural flames
                to intensify and infuse each dish with succulent, smoky flavors.
                At Namma restaurant, that magic comes alive and transports you
                to another place and time. Through its signature spicy flavors,
                Namma offers a delectable journey to food lovers from every
                lifestyle to celebrate superior quality food and share
                intriguing food love stories.
              </p>
              {/* <p>
               
              </p>
              <p>
                They opened additional branches in different cities, spreading
                the flavors of South India far and wide. However, no matter how
                much they expanded, they remained committed to maintaining the
                quality, authenticity, and personal touch that had made Spice
                Haven a beloved establishment.
              </p> */}
            </div>
          </Grid>
        </Grid>
      </div>
      {/* <div
        className="NewFoodsContainer"
        style={{ backgroundImage: `url(${NewFoods})`, marginTop:'20px' }}
      >
        <div className="NewFoodsHeaderContainer">
          We Newley introduced Fusion foods in pure <br /> South Indian flavor
        </div>
        <div className="NewFoodItemsContainer">
          <Slider {...NewFoodSlide}>
            <div className="NewFoodItemsSetContainer">
              <div
                className="NewFoodItemsSet"
                style={{ backgroundImage: `url(${Food1})` }}
              ></div>
              <div className="NewFoodItemsName">
                Paneer Tikka with flavored South Indian masala
              </div>
            </div>
            <div className="NewFoodItemsSetContainer">
              <div
                className="NewFoodItemsSet"
                style={{ backgroundImage: `url(${Food2})` }}
              ></div>
              <div className="NewFoodItemsName">
                Crispy Potato with three dip
              </div>
            </div>
            <div className="NewFoodItemsSetContainer">
              <div
                className="NewFoodItemsSet"
                style={{ backgroundImage: `url(${Food3})` }}
              ></div>
              <div className="NewFoodItemsName">
                Chapati rolled with South Indian Curry masala
              </div>
            </div>
          </Slider>
        </div>
      </div> */}
      {/* <div className="OurCustomerContainer">
        <div className="MainTitle">Our customer’s says about us</div>
        <Slider {...CustomerSlider}>
          <div className="OurCustomerSetContainer">
            <div className="OurCustomerSet">
              <div className="OurCustomerSetImg">
                <img src={User1} alt="React Logo" />
              </div>
              <div className="OurCustomerSetName">
                Celeste <span>(San Francisco)</span>
              </div>
              <div className="OurCustomerSetContent">
                Authentic South Indian flavors at its best! The dosas were
                perfectly crispy, and the sambar had the right balance of
                spices. The service was friendly and efficient. A must-visit for
                all South Indian cuisine lovers.
              </div>
              <img src={UserBG} alt="React Logo" className="UserBgImg" />
            </div>
          </div>
          <div className="OurCustomerSetContainer">
            <div className="OurCustomerSet">
              <div className="OurCustomerSetImg">
                <img src={User2} alt="React Logo" />
              </div>
              <div className="OurCustomerSetName">
                Jaxon <span>(San Francisco)</span>
              </div>
              <div className="OurCustomerSetContent">
                I had an incredible dining experience at this South Indian
                restaurant. The ambiance was warm and inviting, and the staff
                was knowledgeable about the menu. Highly recommended!
              </div>
              <img src={UserBG} alt="React Logo" className="UserBgImg" />
            </div>
          </div>
          <div className="OurCustomerSetContainer">
            <div className="OurCustomerSet">
              <div className="OurCustomerSetImg">
                <img src={User3} alt="React Logo" />
              </div>
              <div className="OurCustomerSetName">
                Seraphina <span>(San Francisco)</span>
              </div>
              <div className="OurCustomerSetContent">
                I'm a big fan of South Indian cuisine, and this restaurant
                definitely hit the mark. The uttapams were deliciously savory,
                and the rasam had a perfect tanginess. I highly recommend trying
                their filter coffee.
              </div>
              <img src={UserBG} alt="React Logo" className="UserBgImg" />
            </div>
          </div>
          <div className="OurCustomerSetContainer">
            <div className="OurCustomerSet">
              <div className="OurCustomerSetImg">
                <img src={User4} alt="React Logo" />
              </div>
              <div className="OurCustomerSetName">
                Maddox <span>(San Francisco)</span>
              </div>
              <div className="OurCustomerSetContent">
                The idlis were fluffy and soft, and the coconut chutney was so
                creamy. The menu had a wide variety of options, and the staff
                was attentive and helpful. I can't wait to go back! This place
                is a gem for anyone craving traditional South Indian fare.
              </div>
              <img src={UserBG} alt="React Logo" className="UserBgImg" />
            </div>
          </div>
        </Slider>
      </div> */}
      <Loader loading={loading} />
    </div>
  );
}

export default LandingScreen;
