import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import LoadImg from '../assets/images/slow-cooker-loader.gif';

  // const Loader = ({ loading }) => {
  //   return (
  //       <Backdrop open={loading} style={{ zIndex: 9999,color: '#223385'}}>
  //         <CircularProgress color="inherit" />
  //       </Backdrop>
  //   );
  // };

  const Loader = ({ loading }) => {
    if (loading) {
      return (
        <div className="loader-container">
          <div className='imageBackground' >
          <img src={LoadImg} alt="Loading..." className="loader-img" />
          </div>

        </div>
      );
    } else {
      return null; // If loading is false, don't render anything
    }
  };
  
  export default Loader;