import React, { useEffect, useState, useContext } from "react";
import { Button, FormControlLabel, Checkbox, ButtonGroup } from "@mui/material";
import DishImg1 from "../assets/images/placeholder.png";
import { addOn, orders, ordersUpdate, ordersRetrieve, searchCatalog } from "../sevices";
import Context from "../Context";
import { isEmptyObj } from '../utils/genericUtilities';
import Loader from "../constant/Loader";
// import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import LoadImg from '../assets/images/slow-cooker-loader.gif';
import { ENV_CONFIG } from "../constant";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { ReactComponent as Minus1 } from '../assets/images/minus1.svg'
import { ReactComponent as Plus1 } from "../assets/images/Plus1.svg";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';




export const Cards = ({ data, unique, setUnique, i, setLoading }) => {



  const [isAddItems, setAddItems] = useState(false);
  const [isAddedMenu, setAddedMenu] = useState(false);
  const [addExtra, setAddExtra] = useState(['samnar', 'vadai', 'chutney', 'minus']);
  const [extraPrice, setExtraPrice] = useState([]);
  const [count, setCount] = useState(1);
  const [variationId, setVariationId] = useState([]);
  const [getOrders, setGetOrders] = useState(false);
  const [price, setPrice] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [updateid, setupdateid] = useState("");
  const [uid, setUid] = useState("");
  const [ordercount, setordercount] = useState({})
  const [update, setupdate] = useState(false)
  const [extraloader, setExtraloader] = useState(false)
  const [newVaritionId, setnewVaritionId] = useState('')
  const [imgUrl, setImgUrl] = useState('')
  const [addItemCount, setAddItemCount] = useState(false)
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  // const [optionValue, setOptionValue] = useState({})

  const { orderData, setOrderData, order, setOrder, version, setVersion } =
    useContext(Context);

  // Add Button Function :

  const addItems = async (modifierId, objId, i) => {
    console.log("jhahdsaas", modifierId);
    setAddItems(true);
    // console.log("kjhbdfhjbhjd",modifier_id);
    console.log('kjkjjzxkjbkj', data);
    console.log("xjhcxgxdg", i);
    await Promise.all(modifierId !== undefined && modifierId.filter((modiId) => {
      if (modiId.enabled === true) {
        modifierFun(modiId.modifier_list_id)
      }
      console.log('kjkjjzxkjbkj', modiId);
    }))



    try {
      let res2 = await addOn(objId);
      let cartItem = {
        name: res2?.data?.object.item_data?.name,
        id: res2?.data?.object?.id,
        variationId: res2?.data?.object?.item_data?.variations[0]?.id,
      };
      console.log('VAAARYRRFGVBNM', cartItem.variationId);
      let id = { id: cartItem.variationId }
      setVariationId(id);

      setUnique([...unique, cartItem]);
    } catch (err) {
      console.log("err", err);
    }
  };

  // Modifier Addon Api Function

  const modifierFun = async (id) => {
    setExtraloader(true)
    // setLoading(true)

    try {
      let res = await addOn(id);
      // setLoading(false)
      let addOnData = res.data?.object?.modifier_list_data?.modifiers;

      console.log('kjdbkjhbdx', addOnData);

      setAddExtra(addOnData);
      console.log('VAAARYRRFGVBNM', res, id);
      setExtraloader(false)
    } catch (err) {
      console.log("err", err);
      setExtraloader(false)
    }
  };
  useEffect(() => {
    // fetchOptionValue()
    if (order) {

      ordervalidate()
    }
    if (data?.item_data?.image_ids?.length) {
      getProductImg()
    }
  }, [])


  const ordervalidate = () => {

    console.log("OOOSASS", orderData);
    const itemdata = data?.item_data?.variations[0]?.id
    const hasVariationId = orderData?.line_items && orderData?.line_items.some(item => item.catalog_object_id.includes(itemdata));
    // const hasVariationId = orderData?.line_items[0]?.catalog_object_id === itemdata;

    if (hasVariationId === true) {
      setAddItems(false);
      setAddedMenu(true)

      const findCount = orderData?.line_items && orderData?.line_items.find((item) => {
        return item.catalog_object_id === itemdata;
      });
      console.log("COCIHNAOC", findCount, orderData?.line_items);
      let id = { id: data?.item_data?.variations[0]?.i }
      setVariationId(id)
      // setVariationIds(data?.item_data?.variations[0]?.id)


      setCount(findCount?.quantity);

    }

  }



  // Add Items Button Function
  console.log('PVNNJNC', price);
  const addMenuItems = async (objId, modifierId) => {
    setLoading(true)
    console.log('PRKVKNVKSNKNND', modifierId);
    if (!modifierId?.modifier_list_info) {
      console.log('PRKVKNVKSNKNNDxxxxxxx', modifierId);
    }

    // e.preventDefault();
    setAddItems(false);
    setAddedMenu(true);

    for (let i of price) {
      extraPrice.push(i);
      let total = extraPrice.reduce((a, c) => {
        return a + c;
      });
      console.log("TOOOOO", total);
      setExtraPrice([total]);
    }
    try {
      let res2 = await addOn(objId);
      console.log('YDGCDCBHBHCBSBDB', res2?.data?.object?.item_data?.variations[0]?.id);
      let id = res2?.data?.object?.item_data?.variations[0]?.id
      setnewVaritionId(res2?.data?.object?.item_data?.variations[0]?.id)
      // if()
      // cartItemData(id,modifierId);
      if (modifierId?.modifier_list_info?.length) {
        console.log('PRKVKNVKSNKNNDxxxxxxx', modifierId);
        cartItemData(id, modifierId?.modifier_list_info[0]?.modifier_list_id);
      } else {
        cartItemData(id, null);
      }
      // setLoading(false)
    } catch (err) {
      console.log("err", err);
      // setLoading(false)
    }
  };


  useEffect(() => {
    update && cartUpdateItem();
    if (count === 0) {
      putCartItem();
      setAddedMenu(false);
    }
  }, [update]);

  //Count Increment Function
  const handleIncrement = async (id) => {
    setLoading(true)
    setAddItemCount(true)
    let value = parseInt(count) + 1;
    setCount(value);

    let res2 = await addOn(id);

    let VariationIds = res2?.data?.object?.item_data?.variations[0]?.id

    setnewVaritionId(VariationIds)


    setupdate(true)
    setupdateid(id.id);
  };


  const fetchOptionValue = async () => {

    try {

      let data = {
        object_types: ["ITEM_OPTION"]
      }
      const response = await searchCatalog(data);
      console.log("CKJNKJNAC", response?.data?.objects[0]?.item_option_data?.values)
      // setOptionValue(response?.data?.objects[0]?.item_option_data?.values)

    } catch (err) {

      console.log("ERRR", err)
    }
  }

  //Count Decrement Function

  const handleDecrement = async (id) => {
    if (count > 0) {
      setAddItemCount(true)
      setCount(count - 1);
    }

    let res2 = await addOn(id);

    let VariationIds = res2?.data?.object?.item_data?.variations[0]?.id

    setnewVaritionId(VariationIds)
    setupdate(true)

    const findUid = orderData?.line_items && orderData?.line_items.find((item) => {
      return item.catalog_object_id === VariationIds;
    })

    console.log("UDABABCJ", findUid, count);


    if (count === 0) {
      setAddItems(true);
    }

    const countZero = parseInt(count) - 1
    console.log("JACAA", countZero);
    if (countZero === 0) {
      // setLoading(true)
      let body = {
        "order": {
          "id": order,
          "location_id": ENV_CONFIG.LOCATION_ID,
          "version": orderData?.version,
        }, "fields_to_clear": [
          `line_items[${findUid?.uid}]`
        ],
        pricing_options: {
          auto_apply_taxes: true,
          auto_apply_discounts: true,
        },
      }
      try {

        const response = await ordersUpdate(body, order);

        if (response?.status === 200) {
          let data = response.data.order;
          // setPrice([]);
          // setExtraPrice([])
          // setCartData([]);
          setOrderData(data)
          setVersion(data?.version)
          console.log("data--sss-->", data.hasOwnProperty('line_items'));
          // if (data.hasOwnProperty('line_items') === false) {
          //   setOrder('')
          //   setOrderData([])
          // }

          // await Promise.all(data?.line_items?.map(async (item) => {
          //   let response = await addOn(item?.catalog_object_id);
          //   let variationKey = Object.keys(
          //     response?.data?.object?.custom_attribute_values
          //   );
          //   item.veg_or_nonveg = response?.data?.object?.custom_attribute_values[variationKey]?.string_value;
          // }));

          // setLoading(false)
          // 
          // console.log("data---->",data);

        }
      } catch (err) {
        console.log("ERRR", err)
        // setLoading(false)
      }



    }

  };

  //Create order Api call
  const cartItemData = async (newid, modifierId) => {
    console.log("fgjhfghsfghfh", orderData?.id, '||||||', order);
    // debugger
    // setLoading(true);

    if (order) {
      // setLoading(true);
      cartUpdateItem(newid);
    } else if (newid === variationId.id || newid) {
      try {
        let body = {
          order: {
            location_id: ENV_CONFIG.LOCATION_ID,
            line_items: [
              {
                catalog_object_id: variationId?.id ? variationId.id : newid,
                modifiers: cartData,
                quantity: `${count}`,
              },
            ],
            pricing_options: {
              auto_apply_taxes: true,
              auto_apply_discounts: true,
            },
          },
        };

        console.log("BODDYDYYDYYDD", body);
        const response = await orders(body);
        let data = response.data.order;
        data['modifier_id'] = response.data.order;

        if (response?.status === 200) {

          setLoading(false);
          setOrder(data?.id);
          setOrderData(data);
          getOrderData(data?.id);
          await localStorage.setItem('OrderData', JSON.stringify(data));
        }

      } catch (err) {
        console.log("ERRR8454", err);
        // setLoading(false)
      }
    }
    // if(newid){
    //   try {
    //     let body = {
    //       order: {
    //         location_id: ENV_CONFIG.LOCATION_ID,
    //         line_items: [
    //           {
    //             catalog_object_id: newid,
    //             modifiers: cartData,
    //             quantity: `${count}`,
    //           },
    //         ],
    //         pricing_options: {
    //           auto_apply_taxes: true,
    //           auto_apply_discounts: true,
    //         },
    //       },
    //     };

    //     const response = await orders(body);
    //     setLoading(false);


    //     let data = response.data.order;
    //     data['modifier_id'] = response.data.order
    //     setOrder(data?.id);
    //     setOrderData(data);
    //     // setUid(data?.line_items[0]?.uid)
    //     getOrderData(data?.id);
    //     // setLoading(false)
    //   } catch (err) {
    //     console.log("ERRR8454", err);
    //     setLoading(false)
    //   }
    // }



    setGetOrders(true);
  };

  const handleOrderItem = () => {
    if (count === 0) {
      addMenuItems(data?.id, data?.item_data)
    } else {
      handleIncrement(data?.id)
    }
  }


  // Update order api call

  const cartUpdateItem = async (newid) => {
    // setLoading(true);
    console.log("CBAHCBBBASB", order);
    if (order && count === 0) {
      setCount(1)
    }
    try {

      if (order && count > 0) {

        let body = {
          order: {
            id: orderData?.id,
            location_id: ENV_CONFIG.LOCATION_ID,
            version: version,
            line_items: [
              {
                catalog_object_id: variationId?.id ? variationId.id : newid,
                modifiers: cartData,
                quantity: `${count}`,
              },
            ],
            pricing_options: {
              auto_apply_taxes: true,
              auto_apply_discounts: true,
            },


          },
        };
        console.log("JKNCJKNACNAN", body)
        const findUid = orderData?.line_items && orderData?.line_items.find((item) => {

          return item.catalog_object_id === newVaritionId;
        })
        console.log("CBNKDBBKJS", findUid);

        let updateBody = {
          order: {
            id: orderData?.id,
            location_id: ENV_CONFIG.LOCATION_ID,
            version: version,
            line_items: [
              {
                uid: findUid?.uid,
                quantity: `${count}`,
              },
            ],
          },
        };

        const hasVariationId = orderData?.line_items && orderData?.line_items.some((item) =>
          item.catalog_object_id.includes(newVaritionId)
        );

        if (hasVariationId === true && findUid?.uid) {
          const updateResponse = await ordersUpdate(updateBody, order);
          setVersion(updateResponse.data.order.version);
          setLoading(false)
          setupdate(false)
          getOrderData();
        } else {
          const updateResponse = await ordersUpdate(body, order);
          getOrderData();

          setVersion(updateResponse.data.order.version);
          // setLoading(false)
          setupdate(false)



        }


        // setLoading(false);
      }
    } catch (err) {
      console.log(err);
      // setLoading(false)
    }
  };

  // Get order data with id
  const getOrderData = async (id) => {
    console.log("Abc123456789");
    try {

      addItemCount && setLoading(true);
      let res = await ordersRetrieve(id ? id : order);
      setLoading(false);
      setVersion(res?.data?.order?.version);
      console.log("OFRTUVSGHDVSD", res?.data?.order);
      setOrderData(res?.data?.order);
    } catch (error) {
      console.log("error---", error);
      // setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (order) {
  //     getOrderData();
  //   }
  // }, []);

  useEffect(() => {
    if (order || addItemCount) {
      getOrderData();
    }
  }, [count]);

  // Remove Item api call
  const putCartItem = async () => {

    const findUid =
      orderData &&
      orderData?.line_items.find((item) => {
        return item.catalog_object_id === variationId;
      });

    try {
      let putBody = {
        fields_to_clear: [`line_items[${findUid?.uid}]`],
        order: {
          id: orderData?.id,
          location_id: ENV_CONFIG.LOCATION_ID,
          version: version,
        },
      };

      // setLoading(true);

      let putResponse = await ordersUpdate(putBody, orderData?.id);


      // setLoading(false);
    } catch (error) {
      console.log("error", error);

    }
  };

  // is veg or non veg key



  // Handle Add on check box
  const handleAddOn = (e, Price, i, modifyId) => {
    e.persist();

    if (e.target.checked === true) {
      unique && unique?.map((item) => {
        let result = {
          catalog_object_id: modifyId,
          quantity: `${count}`,
        };
        console.log("MDODIUDAJ", item.variationId);

        if (cartData.every((item) => item.catalog_object_id !== modifyId)) {
          setCartData([...cartData, result]);
        }
        setPrice([...price, Price]);
        let id = { id: item.variationId }
        // setVariationId(id);
      });
    } else {
      cartData.splice(i, 1);
    }
  };


  console.log("data=====>", data?.item_data?.image_ids);

  const getProductImg = async () => {
    // debugger
    try {
      let response = await addOn(data?.item_data?.image_ids[0]);

      console.log('VAAARYRRFGVBNM', response?.data?.object?.image_data?.url);
      setImgUrl(response?.data?.object?.image_data?.url)

    } catch (err) {
      console.log("err", err);
    }
  }

  // console.log(isVegOrNonveg.variations[0]?.custom_attribute_values[variationKey] === undefined ? "" : isVegOrNonveg.variations[0]?.custom_attribute_values[variationKey]);

  // let variationKey = Object.keys(
  //   data.item_data.variations[0].custom_attribute_values
  // );
  // setIsVegOrNonveg(data?.item_data?.variations[0]?.custom_attribute_values[variationKey]?.string_value);
  // console.log('111111',isVegOrNonveg);
  let OptionvALUE = ''
  return (
    // <div className={`ItemCard ${isAddItems && "AddItems"}`}>
    //   {/* <div className="ItemCardImg" style={{ backgroundImage: `url("https://items-images-sandbox.s3.us-west-2.amazonaws.com/files/f12a455e4ef5699d1cb156f65dc0e0b857322e89/original.png")`}}> */}
    //   {/* <div className="ItemCardImg" style={{ backgroundImage: `url(${DishImg1})` }}> */}

    //   {
    //     imgUrl ?   <div className="ItemCardImg" style={{ backgroundImage: `url(${imgUrl})` }}>
    //     {Object.keys(optionValue).length != 0  &&
    //       optionValue.find((value) =>
    //         value?.id === data?.item_data?.variations[0]?.item_variation_data?.item_option_values[0]?.item_option_value_id
    //       ) && (
    //         <div className={`dishType`} style={{
    //           color: `#${optionValue.find((value) =>
    //             value?.id === data?.item_data?.variations[0]?.item_variation_data?.item_option_values[0]?.item_option_value_id
    //           )?.item_option_value_data?.color || ''}`
    //         }}>
    //           <span className="square" style={{
    //             border: `solid 2px #${optionValue.find((value) =>
    //               value?.id === data?.item_data?.variations[0]?.item_variation_data?.item_option_values[0]?.item_option_value_id
    //             )?.item_option_value_data?.color || ''}`
    //           }} >
    //             <span className="dot" style={{
    //               backgroundColor: `#${optionValue.find((value) =>
    //                 value?.id === data?.item_data?.variations[0]?.item_variation_data?.item_option_values[0]?.item_option_value_id
    //               )?.item_option_value_data?.color || ''}`
    //             }} />
    //           </span>
    //           <span >
    //             {optionValue.find((value) =>
    //               value?.id === data?.item_data?.variations[0]?.item_variation_data?.item_option_values[0]?.item_option_value_id
    //             )?.item_option_value_data?.name || ''}
    //           </span>
    //         </div>
    //       )}
    //   </div> :   <div className="ItemCardImg" style={{ backgroundImage: `url(${DishImg1})` }}>
    //     {Object.keys(optionValue).length != 0  &&
    //       optionValue.find((value) =>
    //         value?.id === data?.item_data?.variations[0]?.item_variation_data?.item_option_values[0]?.item_option_value_id
    //       ) && (
    //         <div className={`dishType`} style={{
    //           color: `#${optionValue.find((value) =>
    //             value?.id === data?.item_data?.variations[0]?.item_variation_data?.item_option_values[0]?.item_option_value_id
    //           )?.item_option_value_data?.color || ''}`
    //         }}>
    //           <span className="square" style={{
    //             border: `solid 2px #${optionValue.find((value) =>
    //               value?.id === data?.item_data?.variations[0]?.item_variation_data?.item_option_values[0]?.item_option_value_id
    //             )?.item_option_value_data?.color || ''}`
    //           }} >
    //             <span className="dot" style={{
    //               backgroundColor: `#${optionValue.find((value) =>
    //                 value?.id === data?.item_data?.variations[0]?.item_variation_data?.item_option_values[0]?.item_option_value_id
    //               )?.item_option_value_data?.color || ''}`
    //             }} />
    //           </span>
    //           <span >
    //             {optionValue.find((value) =>
    //               value?.id === data?.item_data?.variations[0]?.item_variation_data?.item_option_values[0]?.item_option_value_id
    //             )?.item_option_value_data?.name || ''}
    //           </span>
    //         </div>
    //       )}
    //   </div>
    //   }


    //   <div className="ItemCardBody">
    //     <div className="CardBodyDesc">
    //       <div className="title">
    //         <span style={{ textTransform: 'capitalize',   paddingRight:15 ,
    //             minHeight: 42 }}  >{data?.item_data?.name}</span>

    //         {console.log("CNKJDSNJ", data.item_data)}

    //         <span className="cost">
    //           $
    //           {orderData?.line_items && orderData?.line_items ? (
    //             orderData?.line_items?.find(
    //               (item) => item.catalog_object_id === data?.item_data?.variations[0]?.id
    //             ) ? (
    //               (data.item_data.variations[0]?.item_variation_data?.price_money?.amount*count/100).toFixed(2)
    //             ) : (
    //               (data.item_data.variations[0]?.item_variation_data?.price_money?.amount*count/100).toFixed(2)


    //             )
    //           ) : (
    //             (data.item_data.variations[0]?.item_variation_data?.price_money?.amount/100).toFixed(2)

    //           )}
    //         </span>
    //       </div>
    //       <div className="content">
    //       <PerfectScrollbar>
    //         {data?.item_data?.description}
    //         </PerfectScrollbar>
    //         {/* Vada payasam sappadu, a south Indian elaborate typical 3 course meal with sambar, rasam, paruppu, vadai, payasam, vegetables, varuval. */}
    //       </div>
    //       <div className="BtnContainer">
    //         {isAddedMenu ? (
    //           <ButtonGroup
    //             size="small"
    //             aria-label="small outlined button group"
    //           >
    //             <Button onClick={() => handleDecrement(data?.id)}>
    //               -
    //             </Button>

    //             <Button className="itemCountBtn" disabled>
    //               {count}
    //             </Button>
    //             <Button onClick={() => handleIncrement(data?.id)}>
    //               +
    //             </Button>
    //           </ButtonGroup>
    //         ) : data?.item_data?.modifier_list_info != undefined  ?(
    //           <Button
    //             onClick={() =>
    //               addItems(
    //                 data.item_data?.modifier_list_info,
    //                 data.id,
    //                 i
    //               )
    //             }
    //           >
    //             Add
    //           </Button>
    //         ):   <div className="BtnContainer">
    //         {/* <Button onClick={() => {debugger; addMenuItems(data?.id,data?.item_data?.modifier_list_info[0]?.modifier_list_id)}}>Add Item</Button> */}
    //           <Button onClick={() => {addMenuItems(data?.id,data?.item_data)}}>Add Item</Button>
    //         </div>}
    //       </div>
    //     </div>
    //     <div className="AddExtraContainer">
    //       <div className="AddExtraTitle">Add Extra</div>

    //       <div className="extraItemsSetContainer" style={extraloader ? { display: 'flex', justifyContent: 'center', alignItems: 'center' } : null} >

    //         {extraloader ? <div  >


    //           <img src={LoadImg} alt="Loading..." className="loader-img" />


    //         </div> : addExtra?.map((add, i) => (
    //           <div className="extraItemsSet" key={i}>
    //             <FormControlLabel
    //               control={<Checkbox />}
    //               label={add === undefined ? "" : add?.modifier_data?.name}
    //               onChange={(e) =>
    //                 handleAddOn(
    //                   e,
    //                   add?.modifier_data?.price_money?.amount,
    //                   i,
    //                   add?.id
    //                 )

    //               }
    //             />
    //             <span className="itemsCost">
    //               ${" "}
    //               {add === undefined
    //                 ? ""
    //                 : (add?.modifier_data?.price_money?.amount/100).toFixed(2) }
    //             </span>
    //           </div>
    //         ))}
    //       </div>
    //       <div className="BtnContainer">
    //       {/* <Button onClick={() => {debugger; addMenuItems(data?.id,data?.item_data?.modifier_list_info[0]?.modifier_list_id)}}>Add Item</Button> */}
    //         <Button onClick={() => {addMenuItems(data?.id,data?.item_data)}}>Add Item</Button>
    //       </div>
    //     </div>
    //   </div>
    //   {/* <Loader loading={loading} /> */}
    // </div>

    <>
      <div className="cardContainer">


        <div className="cardItem">

          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* <span className="squareBox" >
              <span className="dot2" />
            </span> */}
            <span
              style={{
                textTransform: 'capitalize', fontFamily: 'Bree Serif, serif',
                color: '#002687', fontWeight: 500, paddingLeft: '10px'
              }}
              className="title"
            >{data?.item_data?.name}</span>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className="amount">
              $
              {orderData?.line_items && orderData?.line_items ? (
                orderData?.line_items?.find(
                  (item) => item.catalog_object_id === data?.item_data?.variations[0]?.id
                ) ? (
                  (data.item_data.variations[0]?.item_variation_data?.price_money?.amount * (count !== 0 ? count : 1) / 100).toFixed(2)
                ) : (
                  (data.item_data.variations[0]?.item_variation_data?.price_money?.amount * (count !== 0 ? count : 1) / 100).toFixed(2)


                )
              ) : (
                (data.item_data.variations[0]?.item_variation_data?.price_money?.amount / 100).toFixed(2)

              )}
            </span>
            <div style={{}}>
              {!isAddedMenu && ''

                // <Button
                //   style={{ borderColor: 'transparent', color: '#C58800', textTransform: 'lowercase', textTransform: 'inherit', fontFamily: 'Josefin Sans, sans-serif', border: '1px solid #C58800', padding: '2px 20px 2px 20px' }}
                //   onClick={() => { addMenuItems(data?.id, data?.item_data) }}

                // >
                //   Add Items
                // </Button>

              }
              <div className="cart-item-cast">
                {/* $ {(data?.variation_total_price_money?.amount / 100).toFixed(2)} */}
              </div>
            </div>
            {isAddedMenu &&
              <div style={{ border: '1px solid #C58800', borderRadius: '3px' }}>
                <ButtonGroup
                  size="small"
                  aria-label="small outlined button group"
                >
                  <Button
                    style={{ borderColor: 'transparent', color: '#C58800', padding: '8px' }}
                    onClick={() => handleDecrement(data?.id)}
                  >
                    <Minus1 stroke="red" />
                  </Button>
                  <Button disabled className="ButtonCount"
                    style={{
                      color: '#C58800',
                      fontFamily: 'Josefin Sans, sans-serif',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: 'normal',
                      borderColor: 'transparent'
                    }}
                  >
                    {count}
                  </Button>

                  <Button
                    style={{ borderColor: 'transparent', color: '#C58800', padding: '8px' }}
                    onClick={() => { handleOrderItem(data?.id, data?.item_data) }}
                  >
                    <Plus1 />
                  </Button>
                </ButtonGroup>
                <div className="cart-item-cast">
                  {/* $ {(data?.variation_total_price_money?.amount / 100).toFixed(2)} */}
                </div>
              </div>
            }


          </div>

        </div>
        <p className="desc">
          {data?.item_data?.description}
        </p>
      </div>


      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: '50%' }}>
          <div className="cardItem">

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className="squareBox" >
                <span className="dot2" />
              </span>
              <span
                style={{
                  textTransform: 'capitalize', fontFamily: 'Bree Serif, serif',
                  color: '#002687', fontWeight: 500, paddingLeft: '10px'
                }}
                className="title"
              >{data?.item_data?.name}</span>
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>

              <div style={{ border: '1px solid #E6E6E6', borderRadius: '3px' }}>
                <ButtonGroup
                  size="small"
                  aria-label="small outlined button group"
                >
                  <Button
                    style={{ borderColor: 'transparent', color: '#C58800', padding: '8px' }}
                    onClick={() => handleDecrement(data?.id)}
                  >
                    <Minus1 stroke="red" />
                  </Button>
                  <Button disabled className="ButtonCount"
                    style={{
                      color: '#009506',
                      fontFamily: 'Josefin Sans, sans-serif',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: 'normal',
                      borderColor: 'transparent'
                    }}
                  >
                    {count}
                  </Button>

                  <Button
                    style={{ borderColor: 'transparent', color: '#C58800', padding: '8px' }}
                    onClick={() => { handleOrderItem(data?.id, data?.item_data) }}
                  >
                    <Plus1 />
                  </Button>
                </ButtonGroup>
                <div className="cart-item-cast">
                  {/* $ {(data?.variation_total_price_money?.amount / 100).toFixed(2)} */}
                </div>
              </div>

              {/* <span style={{
                marginLeft: '20px', color: '#C58800',
                fontSize: '18px',
                fontWeight: 400,
                fontFamily: 'Bree Serif'
              }}>
                $
                {orderData?.line_items && orderData?.line_items ? (
                  orderData?.line_items?.find(
                    (item) => item.catalog_object_id === data?.item_data?.variations[0]?.id
                  ) ? (
                    (data.item_data.variations[0]?.item_variation_data?.price_money?.amount * (count !== 0 ? count : 1) / 100).toFixed(2)
                  ) : (
                    (data.item_data.variations[0]?.item_variation_data?.price_money?.amount * (count !== 0 ? count : 1) / 100).toFixed(2)


                  )
                ) : (
                  (data.item_data.variations[0]?.item_variation_data?.price_money?.amount / 100).toFixed(2)

                )}
              </span> */}


            </div>

          </div>
          <div style={{ display: 'fl' }}>
            <Button style={{ color: '#D30000', fontSize: '14px', fontStyle: 'Bree Serif', fontWeight: 400 }}>Clear</Button>

            <Button
              style={{ borderColor: 'transparent', color: '#C58800', padding: '8px' }}
              // onClick={() => handleDecrement(data?.id)}
              onClick={handleOpen}
            >
              Add to cart
            </Button>
          </div>



          <div className="extraItemsSetContainer" style={extraloader ? { display: 'flex', justifyContent: 'center', alignItems: 'center' } : null} >

            {extraloader ? <div  >


              <img src={LoadImg} alt="Loading..." className="loader-img" />


            </div> : addExtra && addExtra?.map((add, i) => (
              <div className="extraItemsSet" key={i}>
                <FormControlLabel
                  control={<Checkbox />}
                  label={add === undefined ? "" : add?.modifier_data?.name}
                  onChange={(e) =>
                    handleAddOn(
                      e,
                      add?.modifier_data?.price_money?.amount,
                      i,
                      add?.id
                    )

                  }
                />
                <span className="itemsCost">

                  {add === undefined
                    ? ""
                    : (add?.modifier_data?.price_money?.amount / 100).toFixed(2)}
                </span>
              </div>
            ))}
          </div>

          <></>

        </Box>
      </Modal >



    </>
  );
};

export default Cards;