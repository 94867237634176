import React, { useEffect, useState, useContext, useRef } from 'react';
import { Grid, Button, TextField, Link, FormControlLabel, Checkbox, ButtonGroup, FormControl, FormLabel, RadioGroup, InputAdornment, IconButton, Menu, Radio } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { ReactComponent as Plus } from '../assets/images/plus.svg';
import { ReactComponent as Minus } from '../assets/images/minus.svg';
import { ReactComponent as Filter } from '../assets/images/filter.svg';
import DishImg1 from '../assets/images/dish1.png'
import DishImg2 from '../assets/images/dish2.png'
import DishImg3 from '../assets/images/dish3.png'
import DishImg4 from '../assets/images/dish4.png'
import './page-styles.scss';
import Slider from 'react-slick';
import { ListCatalog, OrdersUpdate, SearchCatalog, SearchCatalogByCatagoryId, addOn, orders, searchCatalog } from '../sevices';
import Loader from '../constant/Loader';
import Cards from './Cards';
import Context from '../Context';
import Skeleton from '@mui/material/Skeleton';
import LoadImg from '../assets/images/slow-cooker-loader.gif';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import Vegetablebg from '../assets/images/VegetableBg.jpg'
import Arrow from '../assets/images/Arrow.png'
import Arrow2 from '../assets/images/Arrow2.png'
import Arrow3 from '../assets/images/Arrow3.png'
import Arrow4 from '../assets/images/Arrow4.png'
import DailySpeacialTxt from '../assets/images/DailySpeacialTxt.png'
import Default from '../assets/images/default.png'


import { ENV_CONFIG } from '../constant';
const cardSettings = {
    dots: false,
    infinite: false,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 992,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        }
    }, {
        breakpoint: 767,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        }
    }]
};
const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 2,

    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 0.5,
            },
        },
    ],
};


function OurMenuComponent() {

    const [showData, setShowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [categoryData, setCategoryData] = useState([]);
    const [cardloading, setcardloading] = useState(false);
    const [showCategory, setShowCategory] = useState(false);
    const sliderRef = useRef(null);
    // const [optionValue, setOptionValue] = useState({});
    const { unique, setUnique } = useContext(Context);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);



    const [selectedValue, setSelectedValue] = useState("");
    const [defaultSelectedValue, setDefaultSelectedValue] = useState("");
    const [numTabsToShow, setNumTabsToShow] = useState(4); // Number of tabs to show based on system width

    useEffect(() => {
        // Initialize the number of tabs to show based on system width
        updateNumTabsToShow();
        // Listen for window resize events to update the number of tabs to show
        window.addEventListener('resize', updateNumTabsToShow);
        return () => window.removeEventListener('resize', updateNumTabsToShow);
    }, []);
    const updateNumTabsToShow = () => {
        // Update the number of tabs to show based on system width
        const width = window.innerWidth;
        if (width >= 1920) {
            setNumTabsToShow(6);
        }
        if (width >= 992 && width <= 1920) {
            setNumTabsToShow(5);
        } else if (width >= 767 && width <= 992) {
            setNumTabsToShow(8.2);
        } else if (width >= 500 && width <= 767) {
            setNumTabsToShow(3.2);
        } else {
            setNumTabsToShow(1.7);
        }
    };



    const handleRadioChange = (event) => {

        // const selectedIndex = parseInt(event.target.value, 10);
        // log // Get the index of the selected radio button
        // setSelectedTabIndex(selectedIndex); // Update selected tab index
        // setSelectedValue(categoryData[selectedIndex]?.category_data?.name); 
        // let selectedIndex = categoryData.findIndex(element => element === event.target.value);
        const selectedIndex = categoryData.findIndex(category => category.category_data.name === event.target.value);
        setSelectedTabIndex(selectedIndex);


        setSelectedValue(event.target.value);
        // setSelectedTabIndex(parseInt(event.target.value, 10));
    };

    useEffect(() => {
        // fetchData();
        // fetchOptionValue();
        itemData();

        getDataFromLocalStorage()
        getCategoryDataFromLocalStorage()
    }, []);

    const getDataFromLocalStorage = async () => {
        const storedData = await localStorage.getItem('CardsData');
        if (storedData) {
            let filteredstoredData = JSON.parse(storedData)
            setcardloading(false)
            setShowData(filteredstoredData)
        }
    }
    const getCategoryDataFromLocalStorage = async () => {
        const storedDataCategoryData = await localStorage.getItem('CategoryData');
        // const Category = await localStorage.getItem('CategoryText');
        // setSelectedValue(JSON.parse(Category))
        if (storedDataCategoryData) {
            setcardloading(false)
            setCategoryData(JSON.parse(storedDataCategoryData))
        }
    }





    useEffect(() => {
        if (categoryData?.length) {

            showCategory && hanldleButton(selectedValue ? selectedValue : defaultSelectedValue)
        }

    }, [categoryData]);



    // const fetchOptionValue = async () => {
    //   try {
    //     let data = {
    //       object_types: ["ITEM_OPTION"],
    //     };
    //     const response = await searchCatalog(data);

    //     setOptionValue(response?.data?.objects[0]?.item_option_data?.values);
    //   } catch (err) {
    //     console.log("ERRR", err);
    //   }
    // };

    // const fetchData = async () => {
    //   try {
    //     let data = {
    //       object_types: ["ITEM"],
    //     };
    //     setcardloading(true);
    //     const response = await searchCatalog(data);
    //     await localStorage.setItem('CardsData', JSON.stringify(response?.data?.objects));
    //     let data1 = response?.data?.objects?.slice(0, 4);
    //     setcardloading(false);

    //     setFoodData(data1);
    //     // setShowData(data1);
    //   } catch (err) {
    //     console.log("ERRR", err);
    //   }
    // };


    const itemData = async () => {
        try {
            let data = {
                object_types: ["CATEGORY"],
            };
            const response = await searchCatalog(data);
            if (response?.data?.objects) {
                await localStorage.setItem('CategoryData', JSON.stringify(response?.data?.objects));


                setCategoryData(response?.data?.objects);
                // setSelectedValue(response?.data?.objects[0]?.category_data?.nameelectedValue)


                setDefaultSelectedValue(response?.data?.objects[0]?.category_data?.name)
                hanldleButton(response?.data?.objects[0]?.category_data?.name)
            }


        } catch (err) {
            console.log("ERRR", err);
        }
    };

    const hanldleButton = async (data, i, e) => {
        setShowCategory(true)
        // setSelectedTabIndex(i)
        // setSelectedValue(data)
        // if (i !== 0) {
        const selectedIndex = categoryData.findIndex(category => category.category_data.name === data);

        // Move the Slider to the index of the selected category
        sliderRef.current.slickGoTo(selectedIndex / numTabsToShow);;

        // }

        // await localStorage.setItem('CategoryText', JSON.stringify(data))

        // setShowData(null);
        // let foodCategory = await Promise.all(
        let foundCategory = await categoryData.find((dt) => dt?.category_data.name === data)
        const categoriesToMap = foundCategory ? [foundCategory] : categoryData;

        const CatagoryItems = await Promise.all(categoriesToMap?.map(async (category) => {

            const itemResponse = await SearchCatalogByCatagoryId({ category_ids: [category.id] });

            if (itemResponse?.data?.items) {
                setcardloading(true)
                let filteredShowData = itemResponse?.data?.items

                setShowData(filteredShowData)

                setcardloading(false)

                await localStorage.setItem('CardsData', JSON.stringify(itemResponse?.data?.items));
            }
        }));


    };

    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: Math.min(categoryData.length, 1), // Show up to 4 slides or less if there are fewer slides
                    slidesToScroll: Math.min(categoryData.length, 1),
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: Math.min(categoryData.length, 2), // Show up to 4 slides or less if there are fewer slides
                    slidesToScroll: Math.min(categoryData.length, 1),
                },
            },
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: Math.min(categoryData.length, 2), // Show up to 4 slides or less if there are fewer slides
                    slidesToScroll: Math.min(categoryData.length, 0.5),
                },
            },
        ],
    };

    useEffect(() => {
        const handleResize = () => {
            // Force a re-render when the window is resized
            window.dispatchEvent(new Event('resize'));
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleNextTab = () => {
        // console.log('window.innerWidth', window.innerWidth);
        const nextTabIndex = selectedTabIndex + 1;
        const totalTabs = categoryData.length;
        if (nextTabIndex < totalTabs) {
            setSelectedTabIndex(nextTabIndex);
            hanldleButton(categoryData[nextTabIndex]?.category_data?.name);
            setSelectedValue(categoryData[nextTabIndex]?.category_data?.name);
            if (sliderRef.current && sliderRef.current.slickGoTo) {


                if (window.innerWidth <= 500) {
                    sliderRef.current.slickGoTo(nextTabIndex / numTabsToShow);

                }
                if (window.innerWidth <= 1200 && window.innerWidth >= 500) {
                    if (nextTabIndex <= 5) {
                        console.log('numTabsToShow12', numTabsToShow);
                        sliderRef.current.slickGoTo(nextTabIndex / numTabsToShow);
                    } else {
                        console.log('numTabsToShow123', numTabsToShow, 3.5);
                        sliderRef.current.slickGoTo(nextTabIndex / 3.5);
                    }

                } if (window.innerWidth <= 992 && window.innerWidth >= 500) {
                    if (nextTabIndex <= 6) {
                        console.log('numTabsToShow12', numTabsToShow);
                        sliderRef.current.slickGoTo(nextTabIndex / numTabsToShow);
                    } else {
                        console.log('numTabsToShow123', numTabsToShow);
                        sliderRef.current.slickGoTo(nextTabIndex / 7.5);
                    }

                } if (window.innerWidth <= 600 && window.innerWidth >= 500) {
                    if (nextTabIndex <= 6) {
                        console.log('numTabsToShow12', numTabsToShow);
                        sliderRef.current.slickGoTo(nextTabIndex / numTabsToShow);
                    } else {
                        console.log('numTabsToShow123', numTabsToShow);
                        sliderRef.current.slickGoTo(nextTabIndex / 2.5);
                    }

                } else {
                    sliderRef.current.slickGoTo(nextTabIndex / numTabsToShow);
                }

            }

        }
    };

    const handlePreviousTab = () => {
        const preIndex = selectedTabIndex - 1;
        if (preIndex >= 0) {
            setSelectedTabIndex(preIndex);
            hanldleButton(categoryData[preIndex]?.category_data?.name);
            setSelectedValue(categoryData[preIndex]?.category_data?.name);
            if (sliderRef.current && sliderRef.current.slickGoTo) {
                sliderRef.current.slickGoTo(preIndex / numTabsToShow);
            }
        }
    };

    // cc
    console.log('categoryData', categoryData);

    return (
        <>
            {/* <div className='menuPageHeading' >
                <img src={DailySpeacialTxt} className='headingImg' />
            </div> */}
            <div className="OurMenuPage" style={{ backgroundImage: `url(${Vegetablebg})`, marginBottom: '50px', marginTop: '30px' }}>

                <div className="OurMenuHeader">
                    {/* <div className='MainTitle' style={{visibility:'hidden'}}>
                        Our Menu
                    </div> */}
                    {/* <div className="HeaderFilterContainer">
        <TextField
            label="Search"
            variant="outlined"
            margin="normal"
            className='SerchbarInput'
            onChange={(e) => setsearchitem(e.target.value)}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton>
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        /> */}
                    {/* <Button variant="outlined" className='SearchDropDownBtn' onClick={handleOpenMenu}>
            <Filter />
        </Button> */}
                    {/* <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            className="FilterDropdown"
        >
            <FormControl component="fieldset">
                <RadioGroup value={selectedOption} onChange={handleRadioChange}>
                    {categorylist.map((e) => (

                        <FormControlLabel
                            key={e.id}
                            value={e.category_data.name}
                            control={<Radio />}
                            label={e.category_data.name}
                        />
                    ))}
                </RadioGroup>
                <div className="dropdownFooter">
                    <Button variant="contained" color="primary" onClick={handleReset}>
                        Reset
                    </Button>

                </div>
            </FormControl>
        </Menu> */}
                    {/* </div> */}
                </div>

                <div className="MenuItemsSlider">

                    {cardloading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '65px', }}>
                            <img src={LoadImg} alt="Loading..." className="loader-img" />
                        </div>
                    ) : (
                        <div style={{ marginLeft: '50px', marginRight: '50px' }}>
                            <div className="FilterBtnSet" style={{ marginTop: '20px' }}>
                                {/* <div className="itemContainer"> */}

                               {categoryData?.length !== 0 && <div className='arrowBtnContainer'>

                                    <IconButton onClick={handlePreviousTab} >
                                        <img src={selectedTabIndex === 0 ? Arrow2 : Arrow4} className='arrowIcon' />
                                    </IconButton>
                                    <IconButton onClick={handleNextTab} className='arrowBtn'>
                                        <img src={categoryData?.length - 1 === selectedTabIndex ? Arrow3 : Arrow} className='arrowIcon' />
                                    </IconButton>
                                </div>}
                                <div className='scrollBtn'>
                                    <Slider ref={sliderRef} {...settings}>

                                        {categoryData && categoryData?.length > 0 && categoryData?.map((slide, index) => (
                                            <div key={index} style={{ paddingLeft: '20px' }}>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"
                                                    value={selectedValue ? selectedValue : defaultSelectedValue}
                                                    onChange={handleRadioChange}
                                                    onClick={(e) => {
                                                        hanldleButton(slide?.category_data?.name, index, e)

                                                    }

                                                    }
                                                >
                                                    <FormControlLabel
                                                        value={slide?.category_data?.name}
                                                        control={<Radio />}
                                                        label={slide?.category_data?.name}
                                                        checked={selectedTabIndex === index}
                                                    />
                                                </RadioGroup>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>


                                <div style={{ margin: 0, borderBottom: '1px solid #E1E1E1',width: '100%',float: 'left' }} />


                                {showData?.length === 0 ?

                                    <div style={{ display: 'flex', justifyContent: 'center',width: '100%' }}>
                                        <img src={Default} />
                                    </div>

                                    :
                                    <div className="itemContainer" >
                                        {showData && showData?.length > 0 && showData?.map((item, itemIndex) =>

                                            <Cards data={item} key={itemIndex} unique={unique} setUnique={setUnique} setLoading={setLoading} />


                                        )}
                                    </div>}

                            </div>

                        </div>
                        //    </div>
                    )}


                </div >


                <Loader loading={loading} />
            </div>

        </>

    );
}

export default OurMenuComponent;