import React, { useEffect, useState, useContext } from "react";
import {
  Grid,
  Button,
  TextField,
  Link,
  FormControlLabel,
  Checkbox,
  ButtonGroup,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  RadioGroup,
  Radio,
  InputAdornment,
  IconButton,
  Avatar,
  Menu,
} from "@mui/material";
import {
  BrowserRouter as Switch,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import GpsNotFixedIcon from "@mui/icons-material/GpsNotFixed";
// import {  InputLabel, Select, MenuItem } from '@mui/material';
import { ReactComponent as Plus } from "../assets/images/plus.svg";
import { ReactComponent as Minus } from "../assets/images/minus.svg";
import { ReactComponent as DownArrow } from "../assets/images/down-arrow.svg";
import { ReactComponent as DownBlack } from "../assets/images/down-black.svg";
import { ReactComponent as Discount } from "../assets/images/discount.svg";
import Context from "../Context";
import "./page-styles.scss";
import {
  PaymentsSquare,
  addOn,
  orders,
  ordersRetrieve,
  ordersUpdate,
  searchCatalog,
  findModifier,
} from "../sevices";
import Loader from "../constant/Loader";
import validator from "validator";
import { ENV_CONFIG } from "../constant";
// import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CustomSnackBar from "../snackBar";
// import coupon from './coupon.json'

var SqPaymentForm;

function CartScreenComponent() {
  const {
    cartData,
    setCartData,
    order,
    setOrder,
    version,
    setVersion,
    orderData,
    setOrderData, addExtra, setAddExtra
  } = useContext(Context);
  const [counter, setCounter] = useState(0);
  const [itemCounter, setItemCounter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingAdons, setLoadingAdons] = useState(false);

  const [selectedOption, setSelectedOption] = useState("");
  const [deliveryMethod, setDeliveryMethod] = useState("take_away");
  const [initialized, setInitialized] = useState(false);
  const [card, setCard] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState("");
  // const [orderData, setOrderData] = useState([])
  const [name, setFirstname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [customMsg, setCustomMsg] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [modifier, setModifier] = useState([]);
  const navigate = useNavigate();
  const [firstnameError, setfirstnameError] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [phoneError, setPhoneError] = useState("");
  const [optionValue, setOptionValue] = useState({});
  const [openExtra, setOpenExtra] = useState(false);

  const handleDeliveryMethodChange = (event) => {
    setDeliveryMethod(event.target.value);
  };
  const appId = ENV_CONFIG.APP_ID;
  const locationId = ENV_CONFIG.LOCATION_ID;

  const handlePhoneBlur = () => {
    const phoneRegex = /^\d{10}$/;
    const isValid = phoneRegex.test(phone);

    if (!phone) {
      setPhoneError("Mobile number is required");
    } else {
      setPhoneError("");
    }

    return isValid;
  };

  // const HandleApply = () =>{

  // }

  // const handleCoupon = () =>{

  // }

  const idempotencyKey = Date.now();

  const handleSnackbarClick = (status, msgInfo) => {
    setOpenSnackbar(status);
    setCustomMsg(msgInfo);
  };

  useEffect(() => {
    cartItemListData();
    fetchOptionValue();
    modifierFun()
  }, []);

  const handleClear = async () => {
    // const uidArray = orderData && orderData?.line_items.map(item => item.uid);
    // window.location.reload();
    setOrder("");
    setOrderData(null);
  };
  const validateEmail = (email) => {
    return validator.isEmail(email);
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
  };

  const handleIncrement = async (count, uid) => {
    setLoading(true);
    let body = {
      order: {
        // "id": order,
        location_id: ENV_CONFIG.LOCATION_ID,
        version: orderData?.version,
        line_items: [
          {
            uid: uid,
            quantity: `${parseInt(count) + 1}`,
          },
        ],
        pricing_options: {
          auto_apply_taxes: true,
          auto_apply_discounts: true,
        },
      },
    };

    try {
      const response = await ordersUpdate(body, order);
      if (response?.status === 200) {
        let data = response?.data?.order;
        setOrderData(data);
        setVersion(data?.version);
        await Promise.all(
          data?.line_items?.map(async (item) => {
            let response = await addOn(item?.catalog_object_id);
            // let addOnData = res.data?.object?.modifier_list_data?.modifiers;
            // setAddExtra(addOnData);
            let variationKey = Object.keys(
              response?.data?.object?.custom_attribute_values
            );
            item.veg_or_nonveg =
              response?.data?.object?.custom_attribute_values[
                variationKey
              ]?.string_value;
          })
        );

        setLoading(false);
      }
    } catch (err) {
      console.log("ERRR", err);
      setLoading(false);
    }

    // const CatagoryItems = await Promise.all(orderData.line_items.map(async (item) => {
    // const itemResponse = await ordersUpdate('LwFEF1ZTDORTkNObuLExRPXhFi4F', { category_ids: [category.id] });
    // const itemResponse = await ordersUpdate('LwFEF1ZTDORTkNObuLExRPXhFi4F', body);
    // console.log("chdhCSJDNIJN", itemResponse);
    // if (itemResponse?.data?.items) {
    //     return { ...category, itemsData: itemResponse?.data?.items };
    // }
    // return null;
    // }));

    // setCounter(prevCounter => count >= prevCounter ? parseInt(count) + 1 : prevCounter + 1);

    // console.log();
  };

  const handleDecrement = async (count, uid) => {
    setLoading(true);

    const countZero = parseInt(count) - 1;

    if (countZero === 0) {
      let body = {
        order: {
          id: order,
          location_id: ENV_CONFIG.LOCATION_ID,
          version: orderData?.version,
        },
        fields_to_clear: [`line_items[${uid}]`],
      };
      try {
        const response = await ordersUpdate(body, order);
        if (response?.status === 200) {
          let data = response?.data?.order;
          setOrderData(data);
          setVersion(data?.version);
          if (!data.hasOwnProperty("line_items")) {
            navigate("/");
          }
          await Promise.all(
            data?.line_items?.map(async (item) => {
              let response = await addOn(item?.catalog_object_id);
              let variationKey = Object.keys(
                response?.data?.object?.custom_attribute_values
              );
              item.veg_or_nonveg =
                response?.data?.object?.custom_attribute_values[
                  variationKey
                ]?.string_value;
            })
          );

          setLoading(false);
        }
      } catch (err) {
        console.log("ERRR", err);
        setLoading(false);
      }
    }

    // setLoading(true)
    if (parseInt(count) > 0) {
      let body = {
        order: {
          id: order,
          location_id: ENV_CONFIG.LOCATION_ID,
          version: orderData?.version,
          line_items: [
            {
              uid: uid,
              quantity: `${parseInt(count) - 1}`,
            },
          ],
          pricing_options: {
            auto_apply_taxes: true,
            auto_apply_discounts: true,
          },
        },
      };
      try {
        const response = await ordersUpdate(body, order);
        if (response?.status === 200) {
          let data = response?.data?.order;
          setOrderData(data);
          setVersion(data?.version);
          if (!data.hasOwnProperty("line_items")) {
            navigate("/");
          }
          await Promise.all(
            data?.line_items?.map(async (item) => {
              let response = await addOn(item?.catalog_object_id);
              let variationKey = Object.keys(
                response?.data?.object?.custom_attribute_values
              );
              item.veg_or_nonveg =
                response?.data?.object?.custom_attribute_values[
                  variationKey
                ]?.string_value;
            })
          );
          setLoading(false);
        }
      } catch (err) {
        console.log("ERRR", err);
        setLoading(false);
      }

      // setCounter(prevCounter => prevCounter - 1);
    }
  };


  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const displayCounter = counter > 0;

  const initializeCard = async () => {
    setInitialized(true);

    if (!window.Square) {
      throw new Error("Square.js failed to load properly");
    } else {
      const payments = window.Square.payments(appId, locationId);

      const cardInstance = await payments.card();

      await cardInstance.attach("#card-container");

      if (cardInstance) {
        setCard(cardInstance);
      }
      // console.log("CARDSDDSS", cardInstance);
    }
  };
  if (initialized != 1) {
    initializeCard();
  }
  useEffect(() => {
    if (!initialized) {
      initializeCard();
    }
  }, [initialized]);

  // useEffect(() => {
  //     // cartItemListData()
  // }, [orderData])

  // const cartItemData = async () => {
  //     try {
  //         let body = {
  //             "order": {
  //                 "location_id": "LC1BQTNRBNPKQ",
  //                 "line_items": [
  //                     {
  //                         "catalog_object_id": "SLI5MXW3E4W7RNPQNBZHICCL",
  //                         "modifiers": [
  //                             {
  //                                 "catalog_object_id": "YNVEHSWO2HNLFSZ47OKVYES4",
  //                                 "quantity": "1"
  //                             },
  //                             {
  //                                 "catalog_object_id": "NC5K3BXT57WAFEVJ2OWYRXI4"
  //                             }
  //                         ],
  //                         "quantity": "3"
  //                     },
  //                     {
  //                         "catalog_object_id": "M3V4GOQIJ2VHKI3KYUGQLR6Q",
  //                         "modifiers": [
  //                             {
  //                                 "catalog_object_id": "YNVEHSWO2HNLFSZ47OKVYES4",
  //                                 "quantity": "1"
  //                             }

  //                         ],
  //                         "quantity": "2"
  //                     }
  //                 ]
  //             }
  //         }
  //         const response = await orders(body);
  //         if (response?.status === 200) {
  //             let data = response.data.order;

  //             console.log('orderData==>', data)
  //             setOrderData(data)
  //         }
  //     } catch (err) {
  //         console.log("ERRR", err)
  //     }
  // }

  const cartItemListData = async () => {
    if (order) {
      try {
        const response = await ordersRetrieve(order);
        if (response?.status === 200) {
          let data = response?.data?.order;
          console.log("orderData", data);

          await Promise.all(
            data?.line_items?.map(async (item) => {
              let response = await addOn(item?.catalog_object_id);

              // let variationKey = Object.keys(
              //   response?.data?.object?.custom_attribute_values
              // );
              item.veg_or_nonveg =
                response?.data?.object?.item_variation_data?.item_option_values[0]?.item_option_value_id;
            })
          );

          setOrderData(data);
          setVersion(data?.version);
        }
      } catch (err) {
        console.log("ERRR", err);
      }
    }
  };

  const fetchOptionValue = async () => {
    try {
      let data = {
        object_types: ["ITEM_OPTION"],
      };
      const response = await searchCatalog(data);
      console.log("CBABCJABCBABABABABCBASBCAS", response?.data)
      setOptionValue(response?.data?.objects[0]?.item_option_data?.values);
    } catch (err) {
      console.log("ERRR", err);
    }
  };

  // order id = LwFEF1ZTDORTkNObuLExRPXhFi4F
  const tokenize = async (paymentMethod) => {
    // Implement your tokenization logic here.

    try {
      const tokenResult = await paymentMethod.tokenize();

      if (tokenResult.status === "OK") {
        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
        }
        throw new Error(errorMessage);
      }
    } catch (e) {
      console.error("Tokenization failed", e);
      throw e;
    }
  };

  const createPayment = async (token) => {
    const amount_money = {
      amount: orderData?.net_amount_due_money?.amount,
      currency: "USD",
    };

    const billing_address = {
      email: email,
      first_name: name,
    };

    const body = {
      locationId,
      source_id: token,
      amount_money,
      billing_address,
      idempotency_key: window.crypto.randomUUID(),
    };

    try {
      const paymentResponse = await PaymentsSquare(body);

      let ordername =
        paymentResponse?.data?.payment?.billing_address?.first_name;
      let orderid = paymentResponse?.data?.payment?.order_id;

      if (paymentResponse) {
        navigate(`/order?ordername=${ordername}&&orderid=${orderid}`);
      }
    } catch (error) {
      console.log("PAYMEMTSBSS51521", error);
      handleSnackbarClick(true, {
        type: "error",
        msg: "We couldn't process your payment",
      });
      throw error;
    }
  };

  const handlePaymentMethodSubmission = async (event) => {
    event.preventDefault();
    function isEmptyObject(obj) {
      return Object.keys(obj).length === 0;
    }
    const errors = {};
    if (isEmptyObject(orderData) || orderData.line_items == undefined) {
      // console.log("orderData=====>", orderData.line_items);
      handleSnackbarClick(true, { type: "error", msg: "Please select Items" });
      setTimeout(() => {
        navigate("/our-menu");
      }, 1000);
    } else {
      if (!name) {
        errors.name = setfirstnameError("Name is required");
      }
      if (!phone) {
        errors.phone = setPhoneError("Mobile number is required");
      }
      if (!email) {
        errors.email = setIsValidEmail(false);
      }
      if (Object.keys(errors).length > 0) {
        return;
      }

      try {
        if (card) {
          setLoading(true);

          // Disable the submit button as we await tokenization and make a payment request.
          event.target.disabled = true;
          const token = await tokenize(card);

          const paymentResults = await createPayment(token);

          setLoading(false);
          setPaymentStatus("SUCCESS");
          console.debug("Payment Success", paymentResults);
        }
      } catch (e) {
        event.target.disabled = false;
        setLoading(false);
        setPaymentStatus("FAILURE");
        console.error(e.message);
      }
    }
  };
  //APPLE PAY

  // console.log("2121212",openSnackbar);
  const validateFirstname = () => {
    if (!name) {
      setfirstnameError("Name is required");
    } else if (/\d/.test(name)) {
      setfirstnameError("Name should contain only letters.");
    } else {
      setfirstnameError("");
    }
    return name;
  };

  const handlePhoneChange = (e) => {
    // Remove non-numeric characters using a regular expression
    const numericValue = e.target.value.replace(/[^0-9]/g, "");
    setPhone(numericValue);
  };
  const [customizeMenuIndex, setCustomizeMenuIndex] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = async (event, index, catalogObjectId, modifierData) => {

    setLoadingAdons(true)
    setAddExtra([])
    setCustomizeMenuIndex(index);
    setAnchorEl(event.currentTarget);
    try {
      let modifierResponse = await findModifier();

      if (modifierResponse.status === 200) {
        setLoading(false);
        let modifierItem = modifierResponse?.data?.items;
        const foundObject = modifierItem.find(
          (item) => item?.item_data?.variations[0]?.id === catalogObjectId
        );


        // await Promise.all(foundObject?.item_data?.modifier_list_info.filter( (modiId) => {
        //   if(modiId.enabled === true){
        //     modifierFun(modiId.modifier_list_id)
        //   }
        // console.log('kjkjjzxkjbkj', modifierData);


        // }))

        await Promise.all(
          foundObject?.item_data?.modifier_list_info.find((modiId) => {
            if (modiId.enabled === true) {
              modifierFun(modiId.modifier_list_id);
            }
          })
        );
        setLoadingAdons(false)
      }
    } catch (error) {
      console.log("error from modifier", error);
      setLoading(false)
      setLoadingAdons(false)
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //   const handleAddOn = (e, Price, i, modifyId) => {
  //     e.persist();

  //     if (e.target.checked === true) {
  //       unique.map((item) => {
  //         let result = {
  //           catalog_object_id: modifyId,
  //           quantity: `${count}`,
  //         };
  //         console.log("MDODIUDAJ", item.variationId);

  //         if (cartData.every((item) => item.catalog_object_id !== modifyId)) {
  //           setCartData([...cartData, result]);
  //         }
  //         setPrice([...price, Price]);
  //         let id = { id: item.variationId }
  //         // setVariationId(id);
  //       });
  //     } else {
  //       cartData.splice(i, 1);
  //     }
  //   };

  const modifierFun = async (id) => {
    // setLoading(true)
    setAddExtra([]);

    try {
      let res = await addOn(id);

      if (res.status === 200) {
        setOpenExtra(true);
        let addOnData = res.data?.object?.modifier_list_data?.modifiers;
        setAddExtra(addOnData);
        // setLoading(false)
      }
    } catch (err) {
      console.log("err", err);
      // setLoading(false)
    }
  };


  let totalItems = 0;

  if (orderData && Array.isArray(orderData.line_items)) {
    totalItems = orderData.line_items.reduce((a, c) => {
      if (c.quantity) {
        return parseInt(a) + parseInt(c.quantity);
      } else {
        console.error("Missing quantity property in line item:", c);
        return a;
      }
    }, 0);

  } else {
    console.error("Invalid orderData or line_items:", orderData);
  }

  // Now you can access totalItems outside of the if condition.
  // console.log("Total Quantity outside if:", totalItems);

  const handleAddOn = async (
    e,
    add,
    data,
    lineItemUid,
    itemId
  ) => {

    const findUid = data?.modifiers?.find(
      (item) => item?.name === add?.modifier_data?.name
    );
    if (e.target.checked === false) {
      let body = {
        order: {
          id: order,
          location_id: ENV_CONFIG.LOCATION_ID,
          version: orderData?.version,
        },
        fields_to_clear: [
          `line_items[${lineItemUid}].modifiers[${findUid?.uid}]`,
        ],
        pricing_options: {
          auto_apply_taxes: true,
          auto_apply_discounts: true,
        },
      };
      try {
        setLoading(true);
        const response = await ordersUpdate(body, order);
        if (response?.status === 200) {
          e.target.checked = false;
          setLoading(false);
          let data = response?.data?.order;
          setOrderData(data);
          setVersion(data?.version);
          modifierFun()
          await Promise.all(
            data?.line_items?.map(async (item) => {
              let response = await addOn(item?.catalog_object_id);
              if (response.status === 200) {
                let variationKey = Object.keys(
                  response?.data?.object?.custom_attribute_values
                );
                item.veg_or_nonveg =
                  response?.data?.object?.custom_attribute_values[
                    variationKey
                  ]?.string_value;
              }
            })
          );


          setLoading(false);
        }
      } catch (err) {
        console.log("ERRR", err);
        setLoading(false);
      }
    } else if (e.target.checked === true) {
      // Create a copy of the existing line_items array
      const updatedLineItems = data?.line_items ? [...data.line_items] : [];

      // Add the new item to the copy of line_items
      updatedLineItems.push({
        uid: data?.uid,
        modifiers: [
          {
            modifier_id: itemId,
            added: true,
            name: add?.modifier_data?.name,
            base_price_money: {
              amount: add?.modifier_data?.price_money?.amount,
              currency: "USD",
            },
          },
        ],
      });

      let body = {
        order: {
          id: orderData?.id,
          location_id: ENV_CONFIG.LOCATION_ID,
          version: version,
          line_items: updatedLineItems, // Use the updated line_items
        },
      };

      try {
        setLoading(true);
        let addResponse = await ordersUpdate(body, orderData?.id);
        if (addResponse?.status === 200) {
          setLoading(false);
          e.target.checked = true;
          let data = addResponse?.data?.order;
          modifierFun()
          setOrderData(data);
          setVersion(data?.version);
          await Promise.all(
            data?.line_items?.map(async (item) => {
              let response = await addOn(item?.catalog_object_id);
              let variationKey = Object.keys(
                response?.data?.object?.custom_attribute_values
              );
              item.veg_or_nonveg =
                response?.data?.object?.custom_attribute_values[
                  variationKey
                ]?.string_value;
            })
          );

          setLoading(false);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  console.log("optionValue", optionValue);
  return (
    <div className="CartPageContainer">
      <div className="MainTitle">Your Cart</div>
      <Grid container spacing={5}>
        <Grid item lg={6} sm={12} xs={12}>
          <div className="Cart-left-card">
            {orderData?.line_items?.length > 0 &&
              orderData?.line_items.map((data, index) => {

                return (
                  <div className="cart-item-set" key={index}>
                    <div className={"cart-item-NameSet"}>
                      <span
                        className="square1"
                        style={{
                          border: `solid 2px #${(Object.keys(optionValue).length != 0 &&
                            optionValue.find(
                              (value) => value?.id === data?.veg_or_nonveg
                            )?.item_option_value_data?.color) ||
                            ""
                            }`,
                        }}
                      >
                        <span
                          className="dot1"
                          style={{
                            backgroundColor: `#${(Object.keys(optionValue).length != 0 &&
                              optionValue.find(
                                (value) => value?.id === data?.veg_or_nonveg
                              )?.item_option_value_data?.color) ||
                              ""
                              }`,
                          }}
                        />
                      </span>

                      <div className="cart-item-Name">{data?.name}</div>
                      {data?.modifiers !== undefined &&
                        data?.modifiers.map((itemData, i) => (
                          <div key={i} className="show-addons">
                            <span>{itemData?.name}</span>&nbsp;

                            {/* <span>(${itemData?.total_price_money?.amount/100  } )</span> */}
                            <span>${(itemData?.total_price_money?.amount / 100).toFixed(2)}</span>
                          </div>
                        ))
                      }



                      <div className="cart-item-customise">
                        <span>Add Items</span>
                        <DownArrow
                          onClick={(event) =>
                            handleClick(
                              event,
                              index,
                              data?.catalog_object_id,
                              data?.modifiers
                            )
                          }
                        />

                        {index == customizeMenuIndex && open && addExtra ? <Menu
                          id="header_profile_dropdown"
                          className="header-profile-dropdown"
                          aria-labelledby="demo-positioned-button"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <Loader loading={loadingAdons} />
                          {
                            !addExtra?.length ? <MenuItem>No adons</MenuItem> :
                              addExtra.map((item, _index) => {
                                const shouldCheck = data?.modifiers?.map(modifier => {
                                  if (index == customizeMenuIndex) {
                                    return modifier?.catalog_object_id
                                  }
                                  return ''
                                });

                                return (
                                  <MenuItem
                                    className="menuItem"
                                  >
                                    <FormControlLabel
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "100%",
                                      }}
                                      control={<Checkbox checked={shouldCheck?.includes(item?.id)} />}
                                      label={item?.modifier_data?.name}
                                      onChange={(e) =>
                                        handleAddOn(
                                          e,
                                          item,
                                          data,
                                          data?.uid,
                                          item?.id
                                        )
                                      }
                                    />

                                    <div
                                      style={{
                                        flex: 1,
                                        padding: "0 0 0 30px",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      $
                                      {(item?.modifier_data?.price_money?.amount / 100).toFixed(2)}
                                      &nbsp;
                                    </div>
                                  </MenuItem>
                                );
                              })}
                        </Menu> : null}
                      </div>

                    </div>

                    <div className="cartCoutCostSet">
                      <ButtonGroup
                        size="small"
                        aria-label="small outlined button group"
                      >
                        <Button
                          onClick={() =>
                            handleDecrement(data?.quantity, data?.uid)
                          }
                        >
                          <Minus />
                        </Button>
                        <Button className="itemCountBtn" disabled>
                          {data?.quantity}
                        </Button>
                        {/* data?.quantity */}
                        <Button
                          onClick={() =>
                            handleIncrement(data?.quantity, data?.uid)
                          }
                        >
                          <Plus />
                        </Button>
                      </ButtonGroup>
                      <div className="cart-item-cast">
                        $ {(data?.variation_total_price_money?.amount / 100).toFixed(2)}
                      </div>
                    </div>
                  </div>
                );
              })}
            {/* <div className="cartCoutCostSet">
                                <ButtonGroup size="small" aria-label="small outlined button group">
                                    <Button onClick={handleDecrement}><Minus /></Button>
                                    <Button className="itemCountBtn" disabled>
                                        {counter}
                                    </Button>
                                    <Button onClick={handleIncrement}><Plus /></Button>
                                </ButtonGroup>
                                <div className="cart-item-cast">
                                    $ 20
                                </div>
                            </div> */}

            {/* <div className="cart-item-set">
                            <div className="cart-item-NameSet">
                                <div className="cart-item-Name">Ragi Vadai</div>
                                <div className="cart-item-customise">
                                    <span>Customise</span>
                                    <DownArrow />
                                </div>
                            </div>
                            <div className="cartCoutCostSet">
                                <ButtonGroup size="small" aria-label="small outlined button group">
                                    <Button onClick={handleDecrement}><Minus /></Button>
                                    <Button className="itemCountBtn" disabled>
                                        {counter}
                                    </Button>
                                    <Button onClick={handleIncrement}><Plus /></Button>
                                </ButtonGroup>
                                <div className="cart-item-cast">
                                    $ 03
                                </div>
                            </div>
                        </div> */}
            {/* <div className="cart-item-set">
                            <div className="cart-item-NameSet nonVeg">
                                <div className="cart-item-Name">Chicken Biriyani</div>
                                <div className="cart-item-customise">
                                    <span>Customise</span>
                                    <DownArrow />
                                </div>
                            </div>
                            <div className="cartCoutCostSet">
                                <ButtonGroup size="small" aria-label="small outlined button group">
                                    <Button onClick={handleDecrement}><Minus /></Button>
                                    <Button className="itemCountBtn" disabled>
                                        {counter}
                                    </Button>
                                    <Button onClick={handleIncrement}><Plus /></Button>
                                </ButtonGroup>
                                <div className="cart-item-cast">
                                    $ 72
                                </div>
                            </div>
                        </div> */}
            <div className="cart-item-set mrow">
              <Button
                className="ClearBtn"
                disableRipple
                onClick={() => handleClear()}
              >
                Clear cart
              </Button>
              <Button
                className="AddMoreBtn"
                disableRipple
                onClick={() => navigate("/our-menu")}
              >
                <Plus />
                <span>Add more items</span>
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item lg={6} sm={12} xs={12}>
          <div className="BillDetailsCard">
            <div className="SubTitle">Your Order Details</div>
            <div className="BillDetailsSet">
              <span> Total Items</span>
              <span className="BillCost">{totalItems}</span>
            </div>
            {console.log("orderData----", orderData)}
            <div className="BillDetailsSet">
              <span>Amount</span>
              {orderData?.total_money?.amount && (
                <span className="BillCost">
                  $ {(orderData.total_money.amount / 100 + orderData?.total_discount_money?.amount / 100).toFixed(2)}
                  {/* $ {(orderData.total_money.amount / 100).toFixed(2)} */}
                </span>
              )}
              {/* <span className="BillCost">
                $ {(orderData?.total_money?.amount / 100).toFixed(2)}
              </span> */}
            </div>
            <div className="BillDetailsSet">
              <span>Tax</span>
              {/* <span className="BillCost">
                $ {(orderData?.total_tax_money?.amount / 100).toFixed(2)}
              </span> */}
              {orderData?.total_tax_money?.amount && (
                <span className="BillCost">
                  $ {(orderData?.total_tax_money?.amount / 100).toFixed(2)}
                </span>
              )}
            </div>
            <div className="BillDetailsSet">
              <span>Discount</span>
              {/* <span className="BillCost">
                $ {(orderData?.total_discount_money?.amount / 100).toFixed(2)}
              </span> */}
              {orderData?.total_discount_money?.amount && (
                <span className="BillCost">
                  $ {(orderData?.total_discount_money?.amount / 100).toFixed(2)}
                </span>
              )}
            </div>

            {/* <div className="FirstOrderSet"> */}
            {/* <div className="FirstOrderLeft"> */}
            {/* <div className="FirstOrderTop"> */}
            {/* <Discount /> */}
            {/* <TextField className="FormControl"  value={coupon} onChange={handleCoupon} id="coupon" label="Coupon Code" variant="outlined" /> */}
            {/* <span>FIRSTORDER</span> */}
            {/* <DownBlack /> */}
            {/* </div> */}
            {/* <div className="FirstOrderBottom">
                                    Save $ 10 on this order
                                </div> */}
            {/* </div> */}
            {/* <div className="FirstOrderRight">
                                <Button onClick={HandleApply}>Apply</Button>
                            </div> */}
            {/* </div> */}
            <div className="BillDetailsSet">
              <span>Total Amount</span>
              <span className="BillCost textBlue">
                $ {(orderData?.net_amount_due_money?.amount / 100).toFixed(2)}
              </span>
            </div>
            <div className="PersonalInfoForm mbb-none">
              <Grid
                container
                spacing={4}
                rowSpacing={{ xs: 2, sm: 2, md: 2, lg: 4 }}
              >
                <Grid item lg={6} sm={12} xs={12} className="hideWeb">
                  {/* <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="take_away"
                                        name="radio-buttons-group"
                                        className='DelivaryBtnGroup'
                                        onChange={handleDeliveryMethodChange}
                                    >
                                        <FormControlLabel value="take_away" control={<Radio />} label="Take away" />
                                        <FormControlLabel value="delivery" control={<Radio />} label="Delivery" />
                                    </RadioGroup> */}
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                  <TextField
                    className="FormControl"
                    value={name}
                    error={!!firstnameError}
                    helperText={firstnameError}
                    onBlur={validateFirstname}
                    onChange={(event) =>
                      setFirstname(
                        event.target.value.replace(/[^A-Za-z]/gi, "")
                      )
                    }
                    id="name"
                    label="Name"
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                  <TextField
                    className="FormControl"
                    helperText={!isValidEmail ? "Invalid email format" : ""}
                    error={!isValidEmail}
                    value={email}
                    onChange={handleEmailChange}
                    id="email"
                    label="Email"
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                  <TextField
                    className="FormControl"
                    value={phone}
                    error={!!phoneError}
                    helperText={phoneError}
                    onBlur={handlePhoneBlur}
                    onChange={handlePhoneChange}
                    id="mobile"
                    label="Mobile number"
                    variant="outlined"
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      maxLength: 10,
                    }}
                  />
                </Grid>
                {/* <Grid item lg={6} sm={12} xs={12}>
                                    <FormControl fullWidth className="FormControl">
                                        <InputLabel id="demo-simple-select-label">Delivery Timing</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Delivery Timing"
                                        >
                                            <MenuItem value={10}>1 Hour</MenuItem>
                                            <MenuItem value={20}>2 Hours</MenuItem>
                                            <MenuItem value={30}>3 Hours</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid> */}
                {deliveryMethod === "delivery" ? (
                  <>
                    <Grid item lg={6} sm={12} xs={12}>
                      <TextField
                        className="FormControl addressField"
                        id="Address"
                        label="Address"
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton>
                                <GpsNotFixedIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item lg={6} sm={12} xs={12}>
                      <TextField
                        className="FormControl"
                        id="City"
                        label="City"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={6} sm={12} xs={12}>
                      <TextField
                        className="FormControl"
                        id="Zipcode"
                        label="Zipcode"
                        variant="outlined"
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </div>

            <div className="PersonalInfoForm bb-none">
              <Grid
                container
                spacing={4}
                rowSpacing={{ xs: 2, sm: 2, md: 2, lg: 4 }}
              >
                {/* 
                                <Grid item lg={12} sm={12} xs={12}>
                                    <div className="subTitle">
                                        Select payment
                                    </div>
                                </Grid> */}
                <Grid item lg={12} sm={12} xs={12}>
                  {/* <RadioGroup value={selectedOption} onChange={handleOptionChange}>

                                        <FormControlLabel value="credit_card" control={<Radio />} label="Credit Card" />
                                        <FormControlLabel value="apple_pay" control={<Radio />} label="Apple pay" />
                                    </RadioGroup> */}
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                  <span>Credit Card</span>

                  <form
                    id="payment-form"
                    onSubmit={handlePaymentMethodSubmission}
                  >
                    <div id="card-container">
                      {/* <style>
                                                {`#sq-postal-code {display: none;}`}
                                            </style> */}

                      <Grid container spacing={2}>
                        <Grid
                          item
                          lg={12}
                          sm={12}
                          xs={12}
                          container
                          alignItems="center"
                        >
                          <Grid item lg={1} sm={1} xs={1}>
                            {/* Add a Tooltip for the card icon */}

                            {/* Use an IconButton or Avatar to display the card icon */}
                            <IconButton size="small">
                              <div id="sq-card-icon-front">
                                {/* Your SVG code for the front of the card */}
                              </div>
                            </IconButton>
                          </Grid>
                          {/* <Grid item lg={11} sm={11} xs={11}>
                                                        <TextField
                                                            id="card_number"
                                                            label="Card number"
                                                            variant="outlined"
                                                            autoComplete="cc-number"
                                                            autoCapitalize="off"
                                                            autoCorrect="off"
                                                            spellCheck="false"
                                                            inputProps={{ inputMode: 'numeric', maxLength: '24' }}
                                                            aria-required="true"
                                                            aria-invalid="true"
                                                            placeholder="Card number"
                                                            tabIndex="1"
                                                            className="FormControl"
                                                        />
                                                    </Grid> */}
                        </Grid>
                        {/* <Grid item lg={10} sm={12} xs={12}>
                                                    <TextField
                                                        className="FormControl"
                                                        id="card_name"
                                                        label="Name on the card"
                                                        variant="outlined"
                                                    />
                                                </Grid> */}
                        {/* <Grid item lg={2} sm={12} xs={12}>
                                                    <TextField
                                                        className="FormControl"
                                                        id="CVV"
                                                        label="CVV"
                                                        variant="outlined"
                                                    />
                                                </Grid> */}
                      </Grid>
                    </div>
                    <Grid item lg={12} sm={12} xs={12}>
                      <Button type="submit" className="payBtn">
                        Proceed to pay ${" "}
                        {(orderData?.net_amount_due_money?.amount / 100).toFixed(2)}
                      </Button>
                    </Grid>
                  </form>
                </Grid>
                {/* <div
                                    style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "2rem",
                                    }}
                                >
                                    <div
                                        id="apple-pay"
                                        // onClick={() => handlePaymentMethodSubmission(applePay)}
                                        style={{
                                            backgroundColor: "white",
                                            padding: 11,
                                            borderColor: "#bbb",
                                            borderWidth: 1,
                                            boxShadow: "0px 2px 4px #00000033",
                                            fontFamily: "sans-serif",
                                            fontSize: "0.9rem",
                                            marginBottom: 16,
                                            borderRadius: 3,
                                        }}
                                    >
                                        <span>Buy with Apple Pay</span>
                                    </div>


                                </div> */}
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>

      <Loader loading={loading} />
      {openSnackbar ? (
        <CustomSnackBar
          openSnackbar={openSnackbar}
          snackBarMessage={customMsg?.msg}
          status={customMsg?.type}
          handleSnackbarClick={handleSnackbarClick}
        />
      ) : null}
    </div>
  );
}

export default CartScreenComponent;
