import React, { useEffect, useState, useContext } from 'react';
import { Button, Menu, Select, MenuItem, InputLabel, FormControl, TextField, Fade } from '@mui/material';
import { useLocation, Link, useNavigate } from 'react-router-dom';
// import { ReactComponent as Logo } from '../../assets/images/logo.png';
import logo from '../../assets/images/logo.png'
import { ReactComponent as Cart } from '../../assets/images/cart.svg';
import { ReactComponent as Hmenu } from '../../assets/images/mmenu.svg';
import { ReactComponent as Home } from '../../assets/images/home.svg';
import { ReactComponent as About } from '../../assets/images/about.svg';
import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg';
import { ReactComponent as Call } from '../../assets/images/call.svg';


import Ellipse from '../../assets/images/Ellipse.png'
import Context from '../../Context';

import './style.scss';

function HeaderComponent() {
  const { orderData, setOrderData } = useContext(Context)
  const [menuToggled, setMenuToggled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (menuToggled) {
      document.body.classList.add('menuOpen');
    } else {
      document.body.classList.remove('menuOpen');
    }
  }, [menuToggled]);

  const menuToggle = () => {
    setMenuToggled(!menuToggled);
  };

  console.log("AHEADADADADA", orderData);
  return (
    <div className={location.pathname === '/' ? 'HeaderContainer homeHeader' : 'HeaderContainer'}>
      {/* <div className="HeaderContainer"> */}
      <div className="LogoContainer" >
        {/* <Logo onClick={() => navigate('/')} /> */}
        <img src={logo} onClick={() => navigate('/')} />
        <div className="mobileMenuCart">

          <Button component={Link} to="/cart" className={location.pathname === '/cart' ? 'ViewCartBtn activeBtn' : 'ViewCartBtn'}><Cart />{orderData?.line_items?.length >= 1 ? <img className='dotClassSm' src={Ellipse} /> : null}</Button>
          <Button onClick={menuToggle}><Hmenu /></Button>
        </div>
        <div className="MobileOverLay" onClick={menuToggle} />
      </div>

      <div className="HeaderMenuContainer">
        <div className="MobileLog">
          {/* <Logo /> */}
          <img src={logo} onClick={() => navigate('/')} />
        </div>
        <Button onClick={menuToggle} component={Link} to="/" className={location.pathname === '/' ? 'activeBtn' : ''}><Home /><span>Home</span></Button>
        <Button onClick={menuToggle} component={Link} to="/our-story" className={location.pathname === '/our-story' ? 'activeBtn' : ''}><About /><span>About</span></Button>
        <Button onClick={menuToggle} component={Link} to="/our-menu" className={location.pathname === '/our-menu' ? 'activeBtn' : ''}><MenuIcon /><span>Menu</span></Button>
        <Button onClick={menuToggle} component={Link} to="/contactus" className={location.pathname === '/track' ? 'activeBtn' : ''}><Call /><span>Contact us</span></Button>
        {/* <Button onClick={menuToggle} component={Link} to="/cart" className={location.pathname === '/cart' ? 'ViewCartBtn activeBtn' : 'ViewCartBtn'}>View cart {location.pathname === '/cart' ? <img src={Cart1} /> : <img src={cart1} />} {orderData?.line_items?.length >= 1 ? <img className='dotClass' src={Ellipse} /> : null}</Button> */}

      </div>
    </div>
  );
}

export default HeaderComponent;