import React, { useContext, useEffect, useState } from 'react';
import { Grid, Button, TextField, Link, FormControlLabel, Checkbox, ButtonGroup, Card } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { ReactComponent as Plus } from '../assets/images/plus.svg';
import { ReactComponent as Minus } from '../assets/images/minus.svg';
import { ReactComponent as Filter } from '../assets/images/filter.svg';
import OurStoryImg  from "../assets/images/our-story.jpg";
import DishImg1 from '../assets/images/dish1.png'
import DishImg2 from '../assets/images/dish2.png'
import DishImg3 from '../assets/images/dish3.png'
import DishImg4 from '../assets/images/dish4.png'
import './page-styles.scss';
import Slider from 'react-slick';
import { searchCatalog } from '../sevices'
import Cards from './Cards';
import Context from '../Context';
import Skeleton from '@mui/material/Skeleton';
import Loader from '../constant/Loader';
import LoadImg from '../assets/images/slow-cooker-loader.gif';

const cardSettings = {
    dots: false,
    infinite: false,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 992,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
        }
    }, {
        breakpoint: 767,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        }
    }]
};
function OurStoryComponent() {

    const [isAddItems, setAddItems] = useState(false);
    const [isAddedMenu, setAddedMenu] = useState(false);
    const [allItemData, setAllItemData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { cartData, setCartData, unique, setUnique, counter, setCounter } = useContext(Context)
    const [cardloading, setcardloading] = useState(false)
    const [optionValue, setOptionValue] = useState({})

    useEffect(() => {
        // fetchOptionValue()
    }, [])

    const addItems = () => {
        setAddItems(true)
    }
    const addMenuItems = (id) => {

        setAddItems(false);
        setAddedMenu(true)
    }
    const handleIncrement = () => {
        setCounter(prevCounter => prevCounter + 1);
    };

    const handleDecrement = () => {
        if (counter > 0) {
            setCounter(prevCounter => prevCounter - 1);
        }
    };

    const fetchOptionValue = async () => {

        try {

            let data = {
                object_types: ["ITEM_OPTION"]
            }
            const response = await searchCatalog(data);
            console.log("BCKJKNCKND5454", response?.data?.objects[0]?.item_option_data?.values)
            setOptionValue(response?.data?.objects[0]?.item_option_data?.values)

        } catch (err) {

            console.log("ERRR", err)
        }
    }



    const itemData = async () => {
        setcardloading(true)
        try {
            let data = {
                object_types: ["ITEM"]
            }
            // setLoading(true)
            const response = await searchCatalog(data);

            let ourStoryData = response.data.objects;
            // setLoading(false)
            let youMayLike = ourStoryData.filter((yml) => {


                let key = Object.keys(yml?.custom_attribute_values)
                return yml?.custom_attribute_values[key[1]]?.boolean_value === true
            })
            setcardloading(false)
            setAllItemData(youMayLike)

        } catch (err) {
            console.log("ERRR", err)
            setcardloading(false)
        }
    }

    useEffect(() => { itemData() }, [])

    const displayCounter = counter > 0;



    return (
        <div className="OurStoryPage">
            <div className="OurStoryContainer">
                <Grid container spacing={10}>
                    <Grid item lg={6}>
                        <div className="OurStoryImgContainer">
                            {/* <OurStoryImg />
                             */}
                               <img src={OurStoryImg} className="ImgourStory" />
                        </div>
                    </Grid>
                    <Grid item lg={6}>
                        <div className="OurStoryContent">
                            <div className='MainTitle'>
                                Our Story
                            </div>
                            <p>
                At Namma Restaurant, we claim that every meal has a story.
                Traditional dishes prepared over hot stones, coal grills and
                conventional tandoori ovens. Namma’s menu exhibits Indian
                culinary evolution with its melting pot of distinctive regional
                food traditions and cultural heritage perfected in villages and
                culinary hotspots across India
                {/* In the bustling city of San Francisco, a small, family-owned South Indian restaurant named "Spice Haven" opened its doors for the first time. The aroma of freshly ground spices wafted through the air, enticing passersby to step inside and embark on a culinary journey. */}
              </p>
              <p>
                Our chefs expertly prepare Indian food, transporting you to an
                era where natural fire and smokey flavor were the basic cooking
                form. The ancient technique allows direct heat of natural flames
                to intensify and infuse each dish with succulent, smoky flavors.
                At Namma restaurant, that magic comes alive and transports you
                to another place and time. Through its signature spicy flavors,
                Namma offers a delectable journey to food lovers from every
                lifestyle to celebrate superior quality food and share
                intriguing food love stories.
              </p>
                            {/* <p>
                                Today, Spice Haven stands as a testament to the power of passion, perseverance, and the universal language of food. It continues to be a gathering place for people of all backgrounds, united in their love for South Indian cuisine. The story of Spice Haven serves as a reminder that food has the remarkable ability to bridge cultures, create c onnections, and bring joy to those who savor its delights.
                            </p>
                            <p>
                                They opened additional branches in different cities, spreading the flavors of South India far and wide. However, no matter how much they expanded, they remained committed to maintaining the quality, authenticity, and personal touch that had made Spice Haven a beloved establishment.
                            </p> */}
                        </div>
                    </Grid>
                </Grid>
            </div>
            {/* <div className="MenuItemsSlider" style={true ? { display: 'flex', flexDirection: 'column' } : null} >
                <div className="subTitle">
                    You may like
                </div>
                <div >

                    {cardloading ? (
                        <div className='loadCont' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={LoadImg} alt="Loading..." className="loader-img" style={{ width: '80px', height: '80px' }} />
                        </div>
                    ) :
                        <Slider {...cardSettings}>
                            {
                                allItemData?.map((item) => (
                                    <Cards optionValue={optionValue} data={item} key={item.id} loading={loading} unique={unique} setUnique={setUnique} cartData={cartData} setCartData={setCartData} setLoading={setLoading} />
                                ))}
                        </Slider>
                    }

                </div>
            </div> */}
            <Loader loading={loading} />
        </div>
    );
}

export default OurStoryComponent;