// Core
import React from 'react';
import { Navigate, Route, Routes, useNavigate, useLocation } from 'react-router-dom'

import LandingScreen from '../pages/landing';
import OurMenuComponent from '../pages/our-menu';
import OurStoryComponent from '../pages/our-story';
import CartScreenComponent from '../pages/cart';
import TrackOrderComponent from '../pages/track-order';
import ContactPage from '../pages/contactus';
import Privacy from '../components/content/privacy';
import Policy from '../components/content/policy';
import Terms from '../components/content/terms';

export default function RouteComponent() {

    return (
        <Routes>
        
            <Route path="/" element={<LandingScreen pageTitle='home' />} />
            <Route path="/our-menu" element={<OurMenuComponent pageTitle='menu'/>} />
            <Route path="/our-story" element={<OurStoryComponent pageTitle='story'/>} />
            <Route path="/cart" element={<CartScreenComponent pageTitle='cart' />} />
            <Route path="/order" element={<TrackOrderComponent pageTitle='order'/>} />
            <Route path="/contactus" element={<ContactPage pageTitle='contactus'/>} />
            <Route path="/privacy" element={<Privacy pageTitle='privacy'/>} />
            <Route path="/policy" element={<Policy pageTitle='policy'/>} />
            <Route path="/terms" element={<Terms pageTitle='terms'/>} />

        </Routes>
    )
}
