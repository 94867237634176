import React, { useContext, useEffect } from 'react';
import { Grid, Button, TextField, Link, FormControlLabel, Checkbox, ButtonGroup, FormControl, InputLabel, MenuItem, Select, RadioGroup, Radio } from '@mui/material';
import { BrowserRouter as Switch, Route, Routes } from 'react-router-dom';
import { ReactComponent as TrackOrder } from '../assets/images/track-order.svg';
import { ReactComponent as Doordash } from '../assets/images/doordash.svg';

import './page-styles.scss';
import { useState } from 'react';
import Context from '../Context';

function TrackOrderComponent() {
    const [orderName, setOrderName] = useState('')
    const [orderid, setorderid] = useState('')
    const queryParams = new URLSearchParams(window.location.search);
    const Ordersname = queryParams.get('ordername');
    const Ordersid = queryParams.get('orderid');
    const {   order, setOrder ,orderData, setOrderData } = useContext(Context)

    useEffect(() => {
        setOrderName(Ordersname)
        setorderid(Ordersid)
        setOrder('')
        setOrderData(null)

    }, [])


    return (
        <div className="TrackOrderContainer">
            <Grid container justifyContent="center">
                <Grid item lg={6} sm={12}>
                    <div className="TrackOrderCard">
                        <div className="TrackOrderImg">
                            <TrackOrder />

                            <div className="FullWidth">
                                <div className="title"> {orderName}     Your order is placed</div>
                                <div style={{ padding: '20px 60px', fontWeight: 'bold' }}>
                                                Your order id is {orderid}
                                </div>
                                {/* <div className="content">
                                    Sit back, relax, and get ready to indulge as your order makes its way to your doorstep, bringing the flavors of your favorite restaurant right to your home.
                                </div> */}
                                {/* <Button className="payBtn">Track your order</Button> */}
                            </div>
                        </div>
                        {/* <div className="TrackOrderFooter">
                            <div className="content">Your order will be delivered by our delivery partner</div>
                            <Doordash/>
                        </div> */}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default TrackOrderComponent;