import {
  BrowserRouter,
  BrowserRouter as Router
} from 'react-router-dom';
import LayoutComponent from './components/layout'
import './App.scss';
import { Suspense } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import ScrollToTop from './routes/scrollToTop';
import Context from './Context';
import { useState } from 'react';


function App() {

  const [unique, setUnique] = useState([]);
  const [orderData, setOrderData] = useState({});
  const [order, setOrder] = useState('');
  const [version, setVersion] = useState('');
  const [addExtra, setAddExtra] = useState([]);
  

  return (
    <BrowserRouter basename="/">
      <ScrollToTop>
        <Suspense
          fallback={
            <Grid item md={12} height={"90vh"} display={'flex'} justifyContent="center" alignItems={"center"}>
              <CircularProgress />
            </Grid>
          }
        >
          <Context.Provider value={{ unique, setUnique, orderData, setOrderData, order, setOrder, version, setVersion ,addExtra, setAddExtra}}>
            <LayoutComponent />
          </Context.Provider>
        </Suspense>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;

