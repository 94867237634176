
import axiosInstance from '../utils/axiosConfig'
import { ACCOUNT_API_URL } from '../constant/apiUrls';

// Send credentials to the API
const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*'
}
export const ListCatalog = () => {
  return axiosInstance.get(ACCOUNT_API_URL.listCatalog)
}
export const SearchCatalog = (body) => {
  return axiosInstance.post(ACCOUNT_API_URL.searchCatalog,body)
}
export const SearchCatalogByCatagoryId = (body) => {
  return axiosInstance.post(ACCOUNT_API_URL.searchCatalogById,body)
}
export const PaymentsSquare = (body) => {
  return axiosInstance.post(ACCOUNT_API_URL.payments, body, { headers })
}

export const searchCatalog = (body) => {
  return axiosInstance.post(ACCOUNT_API_URL.searchCatalog, body)
}

export const addOn = (id) => {
  return axiosInstance.get(`${ACCOUNT_API_URL.findUnique}/${id}`)
}
export const OrdersUpdate = (id,body) => {
  return axiosInstance.put(`${ACCOUNT_API_URL.orderUpdate}/${id}`,body)
}


export const orders = (body) => {
  return axiosInstance.post(ACCOUNT_API_URL.orders,body,{ headers })
}

export const ordersRetrieve = (id) => {
  return axiosInstance.get(`${ACCOUNT_API_URL.ordersRetrieve}/${id}`)
}

export const ordersUpdate = (body,id) => {
  return axiosInstance.put(`${ACCOUNT_API_URL.ordersUpdate}/${id}`,body,{ headers })
}

export const findModifier = () => {
  return axiosInstance.post(ACCOUNT_API_URL.searchCatalogById)
}