import React from 'react';
import {Grid,Button,TextField,Link} from '@mui/material';
import FooterBg from '../../assets/images/footer-bg.png'
import './style.scss'
import { useNavigate } from 'react-router-dom';

function FooterComponent() {
  
const navigate = useNavigate();
  
    return (
      <footer className="FooterContainer">
       
        <div  className='footerTable'>
         
           
    </div>
          <div className="FooterLeft">
              <div className="FooterMenu">
                <Button onClick={()=>navigate('/terms')}>Terms</Button>
                <Button onClick={()=>navigate('/policy')}>Policy</Button>
                <Button onClick={()=>navigate('/privacy')}>Privacy</Button>
                <Button onClick={()=>navigate('/our-story')}>About</Button>
              </div>
              <div className="FooterAddress">
                  <p> 181 Ranch Dr, Milpitas 95035</p>
                  <p>Email us on: reachusnamma@gmail.com</p>
                  <p>Call: 408-649-3417 and 408-649-3418</p>
              </div>
              <div className="FooterCopyRights">
                Copyright © 2023 All rights are reserved
              </div>
              <img src={FooterBg} alt="Footer" className="FooterBgImg" />
          </div>
          <div className="FooterRight">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3168.3472400675405!2d-121.92257442400387!3d37.42890097207457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fc8dededf5023%3A0x823eeab2d4bc523e!2s181%20Ranch%20Dr%2C%20Milpitas%2C%20CA%2095035%2C%20USA!5e0!3m2!1sen!2sin!4v1696938101884!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            {/* <iframe src="https://www.google.com/maps/place/Puducherry/@11.9362923,79.7723283,13z/data=!3m1!4b1!4m6!3m5!1s0x3a5361ab8e49cfcf:0xcc6bd326d2f0b04e!8m2!3d11.9415915!4d79.8083133!16zL20vMDc3Nzl3?hl=en&entry=ttu" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
            <div className="FooterCopyRights">
                Copyright © 2023 All rights are reserved
              </div>
          </div>
      </footer>
    );
  }
  
  export default FooterComponent;