import { useEffect } from 'react';
import HeaderComponent from '../header'
import FooterComponent from '../footer'
import RouteComponent from '../../routes';


function LayoutComponent() {
  
    return (
      <>
        <HeaderComponent/>
        <RouteComponent/>
        <FooterComponent/>
      </>
    );
  }
  
  export default LayoutComponent;