import React from "react";

import './style.css'

const Policy = () => {
    return<div className="privacy">
    <h1>Policy</h1>
    <p>Device Information: In order to improve your app experience and 
        lend stability to our services to you, we may collect information 
        or employ third party plugins that collect information about the 
        devices you use to access our Services, including the hardware models, 
        operating systems and versions, software, file names and versions, 
        preferred languages, unique device identifiers, advertising identifiers, 
        serial numbers, device motion information, mobile network information, 
        installed applications on device and phone state. The information collected 
        thus will be disclosed to or collected directly by these plugins and may be used 
        to improve the content and/or functionality of the services offered to you. Analytics 
        companies may use mobile device IDs to track your usage of the Swiggy Platform;</p>
        <p>Device Information: In order to improve your app experience and 
        lend stability to our services to you, we may collect information 
        or employ third party plugins that collect information about the 
        devices you use to access our Services, including the hardware models, 
        operating systems and versions, software, file names and versions, 
        preferred languages, unique device identifiers, advertising identifiers, 
        serial numbers, device motion information, mobile network information, 
        installed applications on device and phone state. The information collected 
        thus will be disclosed to or collected directly by these plugins and may be used 
        to improve the content and/or functionality of the services offered to you. Analytics 
        companies may use mobile device IDs to track your usage of the Swiggy Platform;</p>
        <p>Device Information: In order to improve your app experience and 
        lend stability to our services to you, we may collect information 
        or employ third party plugins that collect information about the 
        devices you use to access our Services, including the hardware models, 
        operating systems and versions, software, file names and versions, 
        preferred languages, unique device identifiers, advertising identifiers, 
        serial numbers, device motion information, mobile network information, 
        installed applications on device and phone state. The information collected 
        thus will be disclosed to or collected directly by these plugins and may be used 
        to improve the content and/or functionality of the services offered to you. Analytics 
        companies may use mobile device IDs to track your usage of the Swiggy Platform;</p>
    </div> 
}

export default Policy;