import axios from "axios";
import { ENV_CONFIG } from '../constant/index';

const axiosInstance = axios.create({
  baseURL: ENV_CONFIG.API_URL
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers['Square-Version'] = '2023-08-16'; // Set CORS headers
    config.headers.Authorization = `Bearer ${ENV_CONFIG.API_TOKEN}`
    config.headers['Content-Type'] ='application/json';
    config.headers['Access-Control-Allow-Origin'] ='*';
     
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use((response) => {
  return response;
},
  async (error) => {
    console.log("gdfggdfg",error);
    if (error?.response?.status === 401) {

      console.log('Unauthorized!');
    } else if (error?.response?.status === 403) {
      console.log('Forbidden!',error);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance