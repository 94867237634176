import React from 'react';
import {Grid,Button,TextField,Link} from '@mui/material';
// import FooterBg from '../../assets/images/footer-bg.png';
import FooterBg from '../../src/assets/images/footer-bg.png'
import { ReactComponent as Call } from '../assets/images/call.svg';
import { ReactComponent as Location } from '../assets/images/location.svg';
import { ReactComponent as Mail } from '../assets/images/mail.svg';


import './page-styles.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
function ContactPage() {
  let datas = [
    {
      day : "Monday",
      lunch : "11.30 AM to 3 PM",  
      dinner : " 5.30 PM to 9.30 PM"
    },
    {
      day : "Tuesday",  
      lunch : "Closed",
      
    },
    {
      day : "Wednesday",
      lunch : "11.30 AM to 3 PM",
      dinner : "5.30 PM to 10 PM"
    },
    {
      day : "Thursday",
      lunch : "11.30 AM to 3 PM",   
      dinner : "5.30 PM to 10 PM"
    },
    {
      day : "Friday",
      lunch : "11.30 AM to 3 PM",  
      dinner : "5.30 PM to 10 PM"
    },
    {
      day : "Saturday",
      lunch : "11.30 AM to 3 PM",  
      dinner : "5.30 PM to 10 PM"
    },
    {
      day : "Sunday",
      lunch : "11.30 AM to 3 PM",  
      dinner : "5.30 PM to 10 PM"
    },
  ]

  
    return (
      <div className="contactUsPage">
        <Grid container spacing={2}>
          <Grid lg={12} sm={12} xs={12} item>
            <h1 style={{ color:"#005466"}}>Our Timing</h1> 
          </Grid>
          <Grid lg={6} sm={12} xs={12}>
          <div  className='footerTable'>
         
             <TableContainer component={Paper} sx={{width: 500 }}> 
                  
            <Table sx={{ minWidth: 350 }} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell align="left"><b>Days</b></TableCell>
                  <TableCell align="left" ><b>Lunch</b></TableCell>
                  <TableCell align="left"><b>Dinner</b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {datas.map((data ,i) => (
                  <TableRow key={i}>
                    <TableCell align="left">{data.day}</TableCell>
                    
                    <TableCell align="left" style={ data.lunch == "Closed" ? {color : "#C58800", textAlign : "center" }: null} colSpan={ data.lunch == "Closed" ? 2 : null} >{data.lunch}</TableCell>
                    <TableCell align="left">{data.dinner}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer><br/><br/>
          </div>
          </Grid>
          <Grid lg={6} sm={12} xs={12} item sx={{pt:0}}>
            <div className='subscribeUsCard'>
              <div className="subscribeBody">
                  <div className="title">
                  Contact Us
                  </div>
                  <div className="content">By getting subscribe us you can get update of new authentic South Indian dish</div>
                  <div className="formContainer">
                    <TextField fullWidth id="outlined-basic" label="Name" />
                    <TextField fullWidth id="outlined-basic" label="Email" />
                    <Button className="submitBtn">Submit</Button>
                  </div>
              </div>
              <div className="subscribeFooter">
                  <div className="subscribeFooterSet">
                      <Location/>
                      <div className="FooterContent">181 Ranch Dr, Milpitas 95035.</div>
                  </div>
                  <div className="subscribeFooterSet">
                      <Call/>
                      <div className="FooterContent">408-649-3417 <br/> 408-649-3418</div>
                  </div>
                  <div className="subscribeFooterSet">
                      <Mail/>
                      <div className="FooterContent">reachusnamma@gmail.com</div>
                  </div>
              </div>
            </div>
          </Grid>
        </Grid>
    </div>
          
   
    );
  }
  
  export default ContactPage;