import React, { Fragment } from 'react';
import { Alert, Snackbar } from '@mui/material';
import Slide, { SlideProps } from '@mui/material/Slide';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import './styles.scss';

function TransitionUp(props) {
    return <Slide {...props} direction="down" />;
}

function CustomSnackBar(props) {
    const { autoHideDuration, handleSnackbarClick = f => f, status, snackBarMessage } = props;

    return (
        <Fragment>
            {snackBarMessage && <Snackbar className='msg-snackbar'
                key={TransitionUp ? TransitionUp.name : ''}
                open={props.openSnackbar}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                TransitionComponent={TransitionUp}
                autoHideDuration={autoHideDuration ? autoHideDuration : 6000}
                onClose={() => handleSnackbarClick(false, {})}
            >
                <Alert
                    iconMapping={{
                        success: <CheckCircleOutlineOutlinedIcon />,
                        error: <ErrorOutlineIcon />,
                    }}
                    onClose={() => handleSnackbarClick(false)}
                    severity={status}
                    sx={{
                        width: '100%'
                    }}>
                    {snackBarMessage}
                </Alert>
            </Snackbar>}
        </Fragment>
    )
}

export default CustomSnackBar